import React from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UnauthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'services/b2cService';
import bff, { isCancel } from 'services/bff';
import classes from 'pages/landing/landing.module.scss';
import featureToggles from 'services/featureToggles';
import helpers from 'services/helpers';
import initialise from 'services/initialise';
import navigation from 'services/navigation';
import permissions from 'services/permissions';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';
import AuthNavigation from 'components/authNavigation/authNavigation';
import VetAuthNavigation from 'components/vetAuthNavigation/vetAuthNavigation';
import Button from 'components/base/button/button';
import Footer from 'components/footer/footer';
import LandingModal from 'pages/landing/landingModal/landingModal';
import WelcomeBanner from 'components/welcomeBanner/welcomeBanner';

const Landing = ({
  eventError,
  setEventError,
  setLoading,
  setModal,
  setPermission
}) => {
  const { ready, t } = useTranslation();
  const { instance } = useMsal();
  const [landingVar, setLandingVar] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [isCookieExist, setIsCookieExist] = React.useState(Boolean(storeService.cookie.get.cookieConsent()));

  const closeModal = () => {
    setOpen(!open);
  };
  const history = useHistory();

  const checkCookieTime = 1000;

  const checkMsalStatusCookie = () => {
    const msalStatus = Cookies.get('msal.interaction.status');
    const msalStatusExisting = msalStatus !== null && msalStatus !== undefined;
    if (msalStatusExisting) {
      Cookies.remove('msal.interaction.status');
    }
    //setDisabledLogin(msalStatusExisting);
    setTimeout(
      () => checkMsalStatusCookie(),
      msalStatusExisting ? checkCookieTime * 2 : checkCookieTime
    );
  };

  setTimeout(
    () => checkMsalStatusCookie(),
    checkCookieTime
  );

  const LoginUI = React.lazy(() => import('themeWrappers/loginUI'));

  React.useEffect(() => {
    bff
      .get('/lv')
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal)) {
          setLandingVar(JSON.parse(res.data.statusText));
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          setLandingVar(false);
        }
      });

    if (storeService.session.get.idle()) {
      setModal({
        modalMessage: 'uiError.401',
        modalTitle: 'uiError.401title'
      });
      storeService.session.remove.idle();
    }

    const redirectPage = navigation.home();

    featureToggles.init(setModal); // toggles not available after login if not called.

    if (permissions.isNonBO() && redirectPage !== undefined && !permissions.isRCVSVet()) {
      if (
        storeService.session.get.breedsAll() ||
        storeService.session.get.features() ||
        storeService.session.get.holdings() ||
        storeService.session.get.requestTypes()
      ) {
        setPermission(userRoleType.GENERIC);
        storeService.session.set.permissions(userRoleType.GENERIC);
        window.location.href = redirectPage;
      } else {
        initialise(setModal, setLoading, history, userRoleType.GENERIC);
      }
    }
    if (permissions.isRCVSVet()) {
      setPermission(userRoleType.RCVSVET);
      storeService.session.set.permissions(userRoleType.RCVSVET);
      initialise(setModal, setLoading, history, userRoleType.RCVSVET);
    }
    if (permissions.isBackOffice()) {
      window.location.href = Routing.boVerifyCustomer;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (eventError?.title && eventError?.message) {
      setModal({
        modalClose: setEventError(null),
        modalMessage: eventError.message,
        modalTitle: eventError.title
      });
    }
  }, [eventError, setEventError, setModal]);

  const LandingLink = ({ children, externalWindow, url }) => {
    return (
      <a
        className={classes.landingLink}
        href={url ? url : '#'}
        rel="noopener noreferrer"
        target={externalWindow ? '_blank' : '_self'}
      >
        <i className={'bi bi-chevron-right'} />
        {children}
      </a>
    );
  };

  const setCookie = (cookieValue) => {
    storeService.cookie.set.cookieConsent(cookieValue, { expires: 365 }); // expires value interpreted as days
    setIsCookieExist(true);
  };

  return (
    <>
      {ready &&
        <>
          <React.Suspense fallback={<></>}>
            <LoginUI />
          </React.Suspense>
          <UnauthenticatedTemplate>
            <div className="loginScreen">
              <header>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 col-lg-6">
                      <a href={Routing.root}>
                        <img
                          alt="Livestock Information Service"
                          src="/assets/images/logo-livestock.svg"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </header>
              <WelcomeBanner
                intro={t('landing.introduction')}
                title={t('landing.product')}
              />
              <div className="appContent container">
                <div className="row">
                  <main className="col" role="main">
                    <div className={classes.content}>
                      <div>
                        <h3 className={classes.title}>{t('landing.content1.subHeading')}</h3>
                        <p className={classes.body}>{t('landing.content1.content')}</p>
                        <h3 className={classes.title}>{t('landing.content2.subHeading')}</h3>
                        <p className={classes.topBody}>{t('landing.content2.content')}</p>
                        <p className={classes.body}>{t('landing.content3.lowerContent')}</p>
                        <h3 className={classes.title}>{t('landing.content3.subHeading')}</h3>
                        <p>{t('landing.content3.content')}</p>
                        <AuthNavigation landingVar={landingVar} />
                        <h3 className={classes.title}>{t('landing.content4.subHeading')}</h3>
                        <p>{t('landing.content4.content')}</p>
                        <VetAuthNavigation />
                      </div>
                      <div>
                        <h3 className={classes.title}>{t('landing.linkContent1.heading')}</h3>
                        <ul className={classes.linkGroup}>
                          <li>
                            <LandingLink externalWindow url="https://livestockinformation.org.uk/help-and-support/">
                              {t('landing.linkContent1.link1')}
                            </LandingLink>
                          </li>
                          <li>
                            <LandingLink externalWindow url="https://www.gov.uk/guidance/sheep-and-goat-keepers-register-your-holding-and-flock-or-herd">
                              {t('landing.linkContent1.link2')}
                            </LandingLink>
                          </li>
                        </ul>
                        <h3 className={classes.title}>
                          {t('landing.linkContent2.heading')}
                        </h3>
                        <ul className={classes.linkGroup}>
                          <li>
                            <LandingLink externalWindow url="https://livestockinformation.org.uk/contacts-support/">
                              {t('landing.linkContent2.link1')}
                            </LandingLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
              <Footer />
              {landingVar && (
                <LandingModal
                  onClick={() => {
                    closeModal();
                  }}
                  open={open}
                >
                  <h2 className={classes.heading}>{t('landing.landingVariationModal.heading')}</h2>
                  <p>{t('landing.landingVariationModal.content')}</p>
                  <p>{t('landing.landingVariationModal.content2')}</p>
                  <Button
                    buttonType="primary"
                    label="button.signInVariation"
                    onClick={() => instance.loginRedirect(loginRequest)}
                  />
                  <div className={classes.lowerContent}>
                    <h3>{t('landing.landingVariationModal.linkHeading')}</h3>
                    <LandingLink externalWindow url="https://livestockinformation.org.uk/create-an-account/">
                      {t('landing.landingVariationModal.link')}
                    </LandingLink>
                  </div>
                </LandingModal>
              )}

              {!isCookieExist &&
                <section className="cookie-banner">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12 col-lg-8">
                        <p className="content">{t('cookie.content1')}<br /> {t('cookie.content2')}
                          <a className="policy-link" href="https://livestockinformation.org.uk/cookies/" rel="noreferrer" target="_blank"> {t('cookie.click')} <span className="underline">{t('cookie.here')}</span> {t('cookie.content3')}</a></p>
                      </div>
                      <div className="col-sm-12 col-lg-4">
                        <div className="cookie-button-section">
                          <button aria-label={t('cookie.button1')} className="cookie-button" onClick={() => {
                            setCookie(1);
                          }}>{t('cookie.button1')}</button>
                          <button aria-label={t('cookie.button1')} className="cookie-button" onClick={() => {
                            setCookie(2);
                          }}>{t('cookie.button2')}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              }

            </div>
          </UnauthenticatedTemplate>
        </>
      }
    </>
  );
};

Landing.propTypes = {
  eventError: PropTypes.object,
  setEventError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setPermission: PropTypes.func.isRequired
};

export default Landing;

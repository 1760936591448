import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import bff, { bffResponse, bffStatus, isCancel } from 'services/bff';
import breedsHelper from 'services/breedsHelper';
import classes from 'frontEnd/activityDetails/activityDetails.module.scss';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import he from 'he';
import helpers from 'services/helpers';
import moment from 'moment';
import navigation from 'services/navigation';
import permissions from 'services/permissions';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';
import AddressSummary from 'components/summary/addressSummary/addressSummary';
import AnimalSummary from 'components/animalSummary/animalSummary';
import Button from 'components/base/button/button';
import Confirmation from 'components/base/confirmation/confirmation';
import DateSummary from 'components/summary/dateSummary/dateSummary';
import ExemptionSummary from 'components/summary/exemptionSummary/exemptionSummary';
import FciSummary from 'components/summary/fciSummary/fciSummary';
import Linker from 'components/base/linker/linker';
import ModalConfirm from 'components/base/modalConfirm/modalConfirm';
import PDFButton from 'components/pdfButton/pdfButton';
import TransportSummary from 'components/summary/transportSummary/transportSummary';
import UserComment from 'components/summary/userComment/userComment';

const ActivityDetails = ({
  location,
  setModal,
  setPermission
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const allHoldings = storeService.session.get.holdings();

  const [speciesId, setSpeciesId] = React.useState();
  const [data, setData] = React.useState(null);
  const [loadPending, setLoadPending] = React.useState(true);
  const [requestType, setRequestType] = React.useState(null);
  const [requestId, setRequestId] = React.useState(null);
  const [movementDocumentRef, setMovementDocumentRef] = React.useState(null);
  const [reviewStatus, setReviewStatus] = React.useState(null);
  const [movementDocumentRefCorrected, setMovementDocumentRefCorrected] = React.useState(null);
  const [departureDate, setDepartureDate] = React.useState(null);
  const [arrivalDate, setArrivalDate] = React.useState(null);

  const [eventDate, setEventDate] = React.useState(null);
  const [cancelPending, setCancelPending] = React.useState(false);
  const [extraData, setExtraData] = React.useState({});
  const [batches, setBatches] = React.useState([]);
  const [batchesRejected, setBatchesRejected] = React.useState([]);
  const [individualAnimals, setIndividualAnimals] = React.useState([]);
  const [individualAnimalsRejected, setIndividualAnimalsRejected] = React.useState([]);
  const [transportInformation, setTransportInformation] = React.useState({});
  const [fciDetails, setFciDetails] = React.useState({});
  const [processingFlags, setProcessingFlags] = React.useState({ exempt: false, exemptions: [] });
  const [metFCIWithdrawalPeriods, setMetFCIWithdrawalPeriods] = React.useState();
  const [hideAnimalDetails, setHideAnimalDetails] = React.useState(false);
  const [confirmUndo, setConfirmUndo] = React.useState(false);
  const [confirmAmend, setConfirmAmend] = React.useState(false);

  const [isMissingOrFound, setIsMissingOrFound] = React.useState(false);
  const [isMissing, setIsMissing] = React.useState(false);
  const [printing, setPrinting] = React.useState(false);

  let returnUrl = navigation.userActivity();
  let prevPermissions;
  let prevHolding;
  if (location?.state?.data?.current) {
    if (location.state.data.current.returnUrl) {
      returnUrl = location.state.data.current.returnUrl;
    }
    if (location.state.data.current.permissions) {
      prevPermissions = location.state.data.current.permissions;
    }
    if (location.state.data.current.holding) {
      prevHolding = location.state.data.current.holding;
    }
  }

  const redirectToMovementSummary = () => {
    const data = location.state.data;
    const activityUrl = location.pathname;

    const movementDetails = {
      data: { ...data },
      returnUrl: activityUrl
    };

    history.push(Routing.movementsSummary, movementDetails);
  };

  window.onafterprint = () => {
    setPrinting(false);
    document.body.classList.remove('modal-open');
  };

  const content = {
    [constants.option.requestType.animalsAdded]: {
      cancelButton: 'button.cancelAnimalsAdded',
      cancelPrompt: 'recentDetails.cancelAnimalsAdded',
      cancelTitle: 'title.cancelAnimalsAdded',
      confirmLabel: 'recentDetails.submittedAnimalsAdded',
      contentSubmission: 'recentDetails.contentSubmission',
      dateHeader: 'recentDetails.dateAnimalsAdded',
      failedCancellationLabel: 'recentDetails.failedAnimalsAddedCancellation',
      reference: 'label.reference',
      subTitle: 'recentDetails.activityDetails',
      title: 'recentDetails.titleAnimalsAddedToHoldingRef'
    },

    [constants.option.requestType.birth]: {
      cancelButton: 'button.cancelTagApplication',
      cancelPrompt: 'recentDetails.cancelTagApplication',
      cancelTitle: 'title.cancelTagApplication',
      confirmLabel: 'recentDetails.submittedTagApplication',
      contentSubmission: 'recentDetails.contentSubmission',
      dateHeader: 'recentDetails.applicationDate',
      failedCancellationLabel: 'recentDetails.failedBirthCancellation',
      reference: 'label.reference',
      subTitle: 'recentDetails.activityDetails',
      title: 'recentDetails.titleBirthRef'
    },

    [constants.option.requestType.correctTransfer]: {
      addressHeader: 'recentDetails.movementDirection',
      amendButton: 'button.amendMovement',
      cancelButton: 'button.cancelMovement',
      cancelPrompt: 'recentDetails.cancelCorrectMovement',
      cancelTitle: 'title.cancelCorrectMovement',
      confirmLabel: 'recentDetails.submittedMovement',
      contentSubmission: 'recentDetails.contentSubmission',
      dateHeader: 'recentDetails.movementDate',
      failedCancellationLabel: 'recentDetails.failedMovementCancellation',
      fciHeader: 'recentDetails.fciDetails',
      locationFrom: 'recentDetails.departureHolding',
      locationTo: 'recentDetails.destinationHolding',
      movementRefCorrected: 'label.movementRefCorrected',
      reference: 'label.movementRef',
      subTitle: 'recentDetails.activityDetails',
      title: 'recentDetails.titleCorrectedMovementRef',
      transportHeader: 'recentDetails.transportInformation'
    },

    [constants.option.requestType.death]: {
      addressHeader: 'deaths.locationOfCarcass',
      cancelButton: 'button.cancelDeath',
      cancelPrompt: 'recentDetails.cancelDeathReport',
      cancelTitle: 'title.cancelDeath',
      confirmLabel: 'recentDetails.submittedDeath',
      contentSubmission: 'recentDetails.contentSubmission',
      dateHeader: 'recentDetails.diedDate',
      failedCancellationLabel: 'recentDetails.failedDeathCancellation',
      locationFrom: 'recentDetails.departureHolding',
      locationTo: 'recentDetails.carcassSentTo',
      reference: 'label.reference',
      subTitle: 'recentDetails.activityDetails',
      title: 'recentDetails.titleOnFarmKillRef'
    },

    [constants.option.requestType.movement]: {
      addressHeader: 'recentDetails.movementDirection',
      amendButton: 'button.amendMovement',
      cancelButton: isMissingOrFound ? 'button.cancelRecord' : 'button.cancelMovement',
      cancelPrompt: isMissingOrFound ? 'recentDetails.cancelRecord' : 'recentDetails.cancelMovement',
      cancelTitle: isMissingOrFound ? 'title.cancelRecord' : 'title.cancelMovement',
      cancellationPending: 'recentDetails.cancellationPendingWithUserActivity',
      confirmContent: 'recentDetails.confirmContent',
      confirmLabel: 'recentDetails.submittedMovement',
      contentSubmission: 'recentDetails.contentSubmission',
      dateHeader: 'recentDetails.movementDate',
      failedCancellationLabel: 'recentDetails.failedMovementCancellation',
      fciHeader: 'recentDetails.fciDetails',
      locationFrom: 'recentDetails.departureHolding',
      locationTo: 'recentDetails.destinationHolding',
      reference: 'label.movementRef',
      subTitle: 'recentDetails.activityDetails',
      title: isMissingOrFound ? (isMissing ? 'recentDetails.titleMissingRef' : 'recentDetails.titleFoundRef') : 'recentDetails.titleMovementRef',
      transportHeader: 'recentDetails.transportInformation'
    },

    [constants.option.requestType.movementHandshake]: {
      addressHeader: 'recentDetails.movementDirection',
      cancelButton: 'button.cancelMovementReview',
      cancelPrompt: 'recentDetails.cancelMovementHandshake',
      cancelTitle: 'title.cancelMovementReview',
      confirmLabel: 'recentDetails.cancelMovementSuccess',
      contentSubmission: 'recentDetails.contentSubmission',
      dateHeader: 'recentDetails.reviewDate',
      failedCancellationLabel: 'recentDetails.failedMovementCancellation',
      locationFrom: 'recentDetails.departureHolding',
      locationTo: 'recentDetails.destinationHolding',
      reference: 'label.movementRef',
      subTitle: 'recentDetails.activityDetails',
      title: 'recentDetails.titleReviewHoldingMovementRef'
    },

    [constants.option.requestType.tagApplication]: {
      cancelButton: 'button.cancelTagApplication',
      cancelPrompt: 'recentDetails.cancelTagApplication',
      cancelTitle: 'title.cancelTagApplication',
      confirmLabel: 'recentDetails.submittedTagApplication',
      contentSubmission: 'recentDetails.contentSubmission',
      dateHeader: 'recentDetails.applicationDate',
      failedCancellationLabel: 'recentDetails.failedTagApplicationCancellation',
      reference: 'label.reference',
      subTitle: 'recentDetails.activityDetails',
      title: 'recentDetails.titleApplyTagsBirthRef'
    },

    [constants.option.requestType.tagReplacement]: {
      cancelButton: 'button.cancelTagReplacement',
      cancelPrompt: 'recentDetails.cancelTagReplacement',
      cancelTitle: 'title.cancelTagReplacement',
      confirmLabel: 'recentDetails.submittedTagReplacement',
      contentSubmission: 'recentDetails.contentSubmission',
      failedCancellationLabel: 'recentDetails.failedTagReplacementCancellation',
      reference: 'label.reference',
      dateHeader: 'recentDetails.applicationDate',
      title: 'recentDetails.titleReplaceTagsRef',
      subTitle: 'recentDetails.activityDetails'
    }
  };

  const handleChanges = {
    amendMovement: () => {
      let sessionDepartureDate;
      let sessionArrivalDate;
      if (helpers.species.isCattleId(speciesId)) {
        sessionDepartureDate = helpers.date.formatYYYYMMDD(data.transferDate);
        sessionArrivalDate = helpers.date.formatYYYYMMDD(data.transferDate);
      } else {
        sessionDepartureDate = helpers.date.formatYYYYMMDD(data.movementDocument.departureDetail.departureDateTime);

        if (data.movementDocument.destinationDetail.arrivalDateTime) {
          sessionArrivalDate = helpers.date.formatYYYYMMDD(data.movementDocument.destinationDetail.arrivalDateTime);
        }
      }

      let animalsBeingMoved = [];
      if (data.movementGroups[0].devices?.length > 0) {
        animalsBeingMoved = data.movementGroups[0].devices.map((device) => ({
          rfid: device.rfid,
          tagNumber: device.tagNumber
        }));
      } else if (data.movementGroups[0].devicesWithDetail?.length > 0) {
        animalsBeingMoved = data.movementGroups[0].devicesWithDetail.map((device) => ({
          rfid: device.device.rfid,
          tagNumber: device.device.tagNumber
        }));
      }

      storeService.session.set.movement({
        amend: true,
        movementId: requestId,
        reviewStatus: reviewStatus,
        movementType: data.movementType,
        inBusinessMovement: data.inBusinessMovement,
        requestType,
        departureDate: sessionDepartureDate,
        otherHolding: get.otherHolding(),
        comment: data.comment,
        exempt: data.processingFlags?.length > 0 ? constants.option.exemption.yes : constants.option.exemption.no,
        exemptions: processingFlags.exemptions ? processingFlags.exemptions.map((flag) => {
          const isPermitMoveExemption = Object.values(constants.permitMovesMandatory).some((mandatoryExemption) => mandatoryExemption.id === flag.id);

          return {
            ...flag,
            value: data.processingFlags.some((dataFlag) => flag.id === dataFlag.processingFlagId),
            disabled: helpers.option.movement.isPermitMove(data.movementType) ? isPermitMoveExemption : false
          };
        }) : [],
        selectedAnimalDetails: {
          batchList: data.movementGroups[0].batches.map((batch) => ({
            animalTotal: batch.animalTotal,
            batchNumber: batch.batchNumber,
            description: '',
            tagNumber: batch.batchNumber
          })),
          animalsBeingMoved: animalsBeingMoved?.map((animal) => ({
            ...animal,
            unfit: fciDetails?.unfitAnimals?.find((unfitAnimal) => animal.tagNumber === unfitAnimal.tagNumber)
          }))
        }
      });

      storeService.session.set.movementOtherHolding(get.otherHolding());

      if (sessionArrivalDate) {
        storeService.session.set.movementArrivalDate(sessionArrivalDate);
      }

      if (data.deviceApplication) {
        storeService.session.set.movementRecordTagAllocationDetails(true);
        storeService.session.set.movementTagApplicationDate(helpers.date.formatYYYYMMDD(data.deviceApplication.dateOfApplication));
        storeService.session.set.movementYearOfBirth(String(moment(data.deviceApplication.beginBirthPeriod).year()));
        storeService.session.set.movementBreed({
          id: String(breedsHelper.getBreedId(data.deviceApplication.breed)),
          name: data.deviceApplication.breed
        });
      }

      if (data.movementGroups[0].devicesWithDetail?.length > 0) {
        const device = data.movementGroups[0].devicesWithDetail[0];
        storeService.session.set.movementRecordAnimalDetails(true);
        storeService.session.set.movementYearOfBirth(String(moment(device.beginBirthPeriod).year()));
        storeService.session.set.movementBreed({
          id: String(breedsHelper.getBreedId(device.breed)),
          name: device.breed
        });
        storeService.session.set.movementGender(device.gender);
      }

      if (helpers.species.isCattleId(speciesId)) {
        storeService.session.set.movementRecordTransportInformation(constants.option.recordTransportInformation.no);
        storeService.session.set.movementFciRecordFci(constants.option.recordFCI.no);
      } else {
        const transportDetail = data.movementDocument.transportDetail;
        const departureDetail = data.movementDocument.departureDetail;

        storeService.session.set.movementTransportInformation({
          recordTransportInformation: transportDetail && transportDetail.transporterType ? constants.option.recordTransportInformation.yes : constants.option.recordTransportInformation.no,
          transportedBy: transportDetail && transportDetail.transporterType && constants.option.transporterReverse[transportDetail.transporterType] ? constants.option.transporterReverse[transportDetail.transporterType] : '',
          driverFirstName: transportDetail && transportDetail.transporter && transportDetail.transporter.firstName ? transportDetail.transporter.firstName : '',
          driverLastName: transportDetail && transportDetail.transporter && transportDetail.transporter.lastName ? transportDetail.transporter.lastName : '',
          contactPhone: transportDetail && transportDetail.transporter && transportDetail.transporter.telephoneNumber ? transportDetail.transporter.telephoneNumber : '',
          authorisationNumber: transportDetail && transportDetail.transporterAuthNumber ? transportDetail.transporterAuthNumber : '',
          ownerFirstName: departureDetail && departureDetail.departureOwner && departureDetail.departureOwner.firstName ? departureDetail.departureOwner.firstName : '',
          ownerLastName: departureDetail && departureDetail.departureOwner && departureDetail.departureOwner.lastName ? departureDetail.departureOwner.lastName : '',
          vehicleRegistrationNumber: transportDetail && transportDetail.transportVehicleRegistrationNo ? transportDetail.transportVehicleRegistrationNo : '',
          transporter: transportDetail && transportDetail.transportHaulierName ? transportDetail.transportHaulierName : '',
          departureTime: {
            hours: departureDetail && departureDetail.departureDateTime ? moment(departureDetail.departureDateTime).hours() : null,
            minutes: departureDetail && departureDetail.departureDateTime ? moment(departureDetail.departureDateTime).minutes() : null
          },
          durationOfJourney: {
            days: departureDetail && departureDetail.expectedDurationOfJourney ? departureDetail.expectedDurationOfJourney.days : '',
            hours: departureDetail && departureDetail.expectedDurationOfJourney ? departureDetail.expectedDurationOfJourney.hours : '',
            minutes: departureDetail && departureDetail.expectedDurationOfJourney ? departureDetail.expectedDurationOfJourney.minutes : ''
          }
        });

        const recordFCI = data.movementDocument.fciDetail && typeof data.movementDocument.fciDetail.isAllAnimalsFciCompliant === 'boolean' ? constants.option.recordFCI.yes : constants.option.recordFCI.no;
        storeService.session.set.movementFciRecordFci(recordFCI);

        if (helpers.option.fci.doRecord(recordFCI)) {
          const period = metFCIWithdrawalPeriods.find((item) => data.movementDocument.fciDetail.metFCIWithdrawalPeriodCode === item.code);

          storeService.session.set.movementFciAnimalsSatisfy(data.movementDocument.fciDetail.isAllAnimalsFciCompliant ? constants.option.animalsSatisfy.doSatisfy : constants.option.animalsSatisfy.doNotSatisfy);

          if (data.movementDocument.fciDetail.nonCompliantDevices?.length > 0) {
            storeService.session.set.movementFciUnfitAnimals(data.movementDocument.fciDetail.nonCompliantDevices.map((animal) => animal.tagNumber));
          }
          if (period) {
            storeService.session.set.movementFciWithdrawalPeriod(period);
          }
          if (data.movementDocument.fciDetail.holdingRestrictions) {
            storeService.session.set.movementFciHoldingRestrictions(data.movementDocument.fciDetail.holdingRestrictions);
          }
          if (data.movementDocument.fciDetail.nonCompliantReason) {
            storeService.session.set.movementFciNonComplianceReason(data.movementDocument.fciDetail.nonCompliantReason);
          }
        }
      }

      storeService.session.set.originalMovement(storeService.session.get.movement());

      const returnState = {
        data: location.state.data,
        returnUrl: location.pathname,
        searchState: location.state.searchState
      };

      if (permissions.isGathering()) {
        history.push(navigation.movements(), returnState);
      } else {
        redirectToMovementSummary();
      }
    },

    cancel: () => {
      if (
        helpers.option.requestType.isAnimalsAdded(requestType) ||
        helpers.option.requestType.isCorrectedMovement(requestType) ||
        helpers.option.requestType.isMovement(requestType) ||
        helpers.option.requestType.isMovementHandshake(requestType) ||
        helpers.option.requestType.isDeath(requestType) ||
        helpers.option.requestType.isTagApplication(requestType) ||
        helpers.option.requestType.isTagReplacement(requestType) ||
        helpers.option.requestType.isBirth(requestType)
      ) {
        setConfirmUndo(false);
        setCancelPending(true);

        bff
          .post('/undoRequest', {
            poll: config.POLLS_ENABLED,
            requestId
          })
          .then((res) => {
            const { data } = res;
            storeService.session.set.confirmRequestType(requestType);
            storeService.session.set.confirmRequestId(bffResponse.requestId(data));
            storeService.session.set.confirmPollingStatus(bffResponse.status(data));

            storeService.session.set.confirmPollingErrors(bffResponse.errors(data));
            storeService.session.set.confirmPollingWarnings(bffResponse.warnings(data));

            history.push(Routing.activityUndo + requestId);
            helpers.scrollToTop();
          })
          .catch((error) => {
            setCancelPending(false);
            errors.BFF(error, setModal);
          });
      }
    }
  };

  const get = {
    tagClassname: (status) => {
      return 'tag ' + helpers.tag.getColour(status);
    },

    flag: (flagId, flags) => {
      return flags.find((flag) => flag.id === flagId);
    },

    tagNumber: (string) => {
      return string && string.tagNumber ? string.tagNumber : t('label.notSpecified');
    },

    otherHolding: () => {
      if (data.inBusinessMovement) {
        return {
          value: data.sourceHolding
        };
      }

      switch (data.movementType) {
        case constants.option.movement.off:
          return {
            value: data.destinationHolding
          };
        case constants.option.movement.on:
          return {
            value: data.sourceHolding
          };
        default:
          return null;
      }
    }
  };

  const fetch = {
    activityData: (stateData, source1, source2, source3) => {
      bff
        .get('/request', {
          cancelToken: source1.token,
          params: {
            requestId: stateData.requestId,
            documentRef: stateData.movementDocumentRef,
            requestType: stateData.requestType
          }
        })
        .then((res) => {
          if (helpers.response.isValid(res.data, setModal, setLoadPending)) {
            const resData = res.data;
            const holdingToSelect = resData.userHolding ? resData.userHolding : resData.holding;

            const isPermitMoveDates = (data) => (
              data.movementDocument?.departureDetail?.departureDateTime &&
              data.movementDocument?.destinationDetail?.arrivalDateTime &&
              (helpers.date.format(data.movementDocument?.departureDetail?.departureDateTime) === helpers.date.format(data.movementDocument?.destinationDetail?.arrivalDateTime))
            );

            const isPermitMoveCheck = (data) => {
              if (data?.movementDocument) {
                if (isPermitMoveDates(data)) {
                  if (data?.sourceHolding) {
                    return data.sourceHolding === data.destinationHolding;
                  }
                  return data.fromHolding === data.toHolding;
                }
              }
              return false;
            };

            const getMovementType = () => {
              let movementType;

              if (isPermitMoveCheck(resData)) {
                movementType = constants.option.movement.permit;
              } else if (resData.userHolding === resData.sourceHolding) {
                movementType = constants.option.movement.off;
              } else {
                movementType = constants.option.movement.on;
              }

              return movementType;
            };
            const defaultHolding = prevHolding ? prevHolding.value : Object.keys(allHoldings).length > 0 && Object.keys(allHoldings)[0];
            const holding = holdingToSelect && allHoldings[holdingToSelect] ? allHoldings[holdingToSelect] : allHoldings[defaultHolding];
            storeService.session.set.holding(holding);
            storeService.session.set.permissions(holding.role);
            setPermission(holding.role);

            breedsHelper.update({
              id: helpers.species.nameToId(resData.species),
              name: resData.species
            });
            setSpeciesId(helpers.species.nameToId(resData.species));

            if (resData.documentRef) {
              setMovementDocumentRef(resData.documentRef);
            } else if (resData.movementDocument) {
              setMovementDocumentRef(resData.movementDocument.movementDocumentRef);
            }

            if (resData.movementRefCorrected) {
              setMovementDocumentRefCorrected(resData.movementRefCorrected);
            }

            setData({
              ...resData,
              inBusinessMovement: helpers.option.movement.isPermitMove(getMovementType()) ? false : Boolean(allHoldings[resData.sourceHolding] && allHoldings[resData.destinationHolding]),
              movementType: helpers.option.requestType.isAnimalsAdded(stateData.requestType) ? null : getMovementType(),
              sourceHolding: resData.fromHolding ? resData.fromHolding : resData.sourceHolding,
              destinationHolding: resData.toHolding ? resData.toHolding : resData.destinationHolding,
              status: resData.isFullUndone ? constants.status.cancelled : resData.requestStatus
            });

            let tempBatches = [];
            let tempBatchesRejected = [];
            let tempIndividualAnimals = [];
            let tempIndividualAnimalsRejected = [];
            let dob;
            let isSheepOrGoats;

            switch (stateData.requestType) {
              case constants.option.requestType.animalsAdded:
                setEventDate(resData.dateAnimalsWereLocated);
                tempIndividualAnimals = helpers.animal.addDobAndAgeForDisplay(
                  resData.devicesWithDetail
                    .map((animal) => ({
                      ...animal,
                      tagNumber: animal.device.tagNumber,
                      breedName: animal.breed,
                      genderName: animal.gender
                    }))
                );
                break;

              case constants.option.requestType.birth:
                setEventDate(resData.dateOfApplication);
                tempBatches = [];
                tempIndividualAnimals = createBirthList(resData.applicationItems).map((item) => ({
                  ...item,
                  genotype: resData.genotype
                }));
                break;

              case constants.option.requestType.death:
                setEventDate(resData.killDate);
                if (resData.deathofUntaggedAnimals && resData.deathofUntaggedAnimals.animalTotal > 0) {
                  setHideAnimalDetails(true);
                } else {
                  tempBatches = resData.batchDetails;
                }
                tempIndividualAnimals = resData.killedDevices;
                break;

              case constants.option.requestType.correctTransfer:
              case constants.option.requestType.movement: {
                fetch.exemptions(res, source2);
                setEventDate(resData.transferDate);

                if (resData.movementDocument.departureDetail) {
                  setDepartureDate(resData.movementDocument.departureDetail.departureDateTime);
                }
                if (resData.movementDocument.destinationDetail) {
                  setArrivalDate(resData.movementDocument.destinationDetail.arrivalDateTime);
                }

                setIsMissingOrFound(helpers.address.isUnknown(resData.destinationHolding) || helpers.address.isUnknown(resData.sourceHolding));
                setIsMissing(helpers.address.isUnknown(resData.destinationHolding));

                resData.movementGroups.forEach((group) => {
                  if (group.batches?.length > 0) {
                    tempBatches = [...tempBatches, ...group.batches];
                  }
                  if (group.devices?.length > 0) {
                    tempIndividualAnimals = [...tempIndividualAnimals, ...group.devices];
                  }
                  if (group.devicesWithDetail?.length > 0) {
                    tempIndividualAnimals = [...tempIndividualAnimals, ...group.devicesWithDetail.map((device) => ({
                      beginBirthPeriod: device.beginBirthPeriod,
                      breed: device.breed,
                      endBirthPeriod: device.endBirthPeriod,
                      gender: device.gender,
                      rfid: device.rfid,
                      tagNumber: device.device.tagNumber
                    }))];
                  }
                });

                const movementDocument = resData.movementDocument;
                if (movementDocument) {
                  const fciDetail = movementDocument.fciDetail;
                  if (fciDetail) {
                    tempIndividualAnimals = tempIndividualAnimals.map((animal) => ({
                      ...animal,
                      unfit: fciDetail.nonCompliantDevices.find((unfitAnimal) => animal.tagNumber === unfitAnimal.tagNumber)
                    }));

                    helpers.get.metFciWithdrawalPeriods(source3.token)
                      .then((res2) => {
                        if (helpers.response.isValid(res2.data, setModal)) {
                          const period = res2.data.find((item) => fciDetail.metFCIWithdrawalPeriodCode === item.code);

                          setMetFCIWithdrawalPeriods(res2.data);
                          setFciDetails({
                            recordFCI: typeof fciDetail.isAllAnimalsFciCompliant === 'boolean' ? constants.option.recordFCI.yes : constants.option.recordFCI.no,
                            animalsSatisfy: fciDetail.isAllAnimalsFciCompliant ? constants.option.animalsSatisfy.doSatisfy : constants.option.animalsSatisfy.doNotSatisfy,
                            unfitAnimals: fciDetail.nonCompliantDevices,
                            nonComplianceReason: fciDetail.nonCompliantReason,
                            withdrawalPeriod: period ? period : null,
                            holdingRestrictions: fciDetail.holdingRestrictions
                          });
                        }
                      });
                  }

                  const transportDetail = movementDocument.transportDetail;
                  if (transportDetail) {
                    setTransportInformation({
                      recordTransportInformation: Boolean(transportDetail.transporterType) && (helpers.option.transporter.isDepartureKeeper(transportDetail.transporterType) || helpers.option.transporter.isDestinationKeeper(transportDetail.transporterType) || helpers.option.transporter.isHaulier(transportDetail.transporterType)),
                      transportedBy: transportDetail.transporterType ? constants.option.transporterReverse[transportDetail.transporterType] : null,
                      vehicleRegistrationNumber: transportDetail.transportVehicleRegistrationNo,
                      contactPhone: transportDetail.transporter ? transportDetail.transporter.telephoneNumber : null,
                      durationOfJourney: resData.movementDocument.departureDetail.expectedDurationOfJourney
                    });
                  } else {
                    setTransportInformation({
                      recordTransportInformation: constants.option.recordTransportInformation.no
                    });
                  }
                }
                break;
              }

              case constants.option.requestType.movementHandshake:
                fetch.exemptions(res, source2);
                setEventDate(resData.requestDate);
                tempBatches = resData.reviewMovement.acceptedBatches;
                tempBatchesRejected = resData.reviewMovement.rejectedBatches;
                tempIndividualAnimals = resData.reviewMovement.acceptedDevices;
                tempIndividualAnimalsRejected = resData.reviewMovement.rejectedDevices;
                break;

              case constants.option.requestType.tagApplication:
                isSheepOrGoats = helpers.species.isSheepId(storeService.session.get.species().id) || helpers.species.isGoatId(storeService.session.get.species().id);
                tempBatches = resData.batches;
                tempIndividualAnimals = resData.devices;
                dob = isSheepOrGoats ? moment(resData.dateOfBirthRangeStart).year() : helpers.date.format(resData.dateOfBirthRangeStart);

                setEventDate(resData.dateOfApplication);
                setExtraData({
                  dob,
                  breed: resData.breed,
                  gender: resData.gender,
                  genotype: resData.genotype
                });
                break;

              case constants.option.requestType.tagReplacement:
                setEventDate(resData.replacementDate);
                resData.replaceItems.forEach((item) => {
                  if (item.batchReplacing) {
                    tempBatches.push({
                      animalTotal: item.batchReplacing.animalTotal,
                      oldBatchNumber: item.batchReplaced ? item.batchReplaced.batchNumber : t('label.unknown'),
                      newBatchNumber: item.batchReplacing.batchNumber
                    });
                  } else if (item.deviceReplacing) {
                    if (item.batchReplaced) {
                      tempIndividualAnimals.push({
                        oldTagNumber: item.batchReplaced.batchNumber,
                        newTagNumber: item.deviceReplacing.tagNumber
                      });
                    } else if (item.deviceReplaced) {
                      tempIndividualAnimals.push({
                        oldTagNumber: item.deviceReplaced.tagNumber,
                        newTagNumber: item.deviceReplacing.tagNumber
                      });
                    } else {
                      tempIndividualAnimals.push({
                        oldTagNumber: constants.unknown,
                        newTagNumber: item.deviceReplacing.tagNumber
                      });
                    }
                  }
                });
                break;
              default:
                break;
            }

            setBatches(tempBatches);
            setBatchesRejected(tempBatchesRejected);
            setIndividualAnimals(tempIndividualAnimals);
            setIndividualAnimalsRejected(tempIndividualAnimalsRejected);

            setLoadPending(false);
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            errors.BFF(error, setModal);
          }
        });
    },

    exemptions: (res, source) => {
      let flags = [];

      if (res.data.processingFlags?.length > 0) {
        flags = res.data.processingFlags;
      } else if (res.data.movementDetailsProcessingFlags?.length > 0) {
        flags = res.data.movementDetailsProcessingFlags;
      }

      if (flags?.length === 0) {
        return;
      }

      helpers.get.processingFlags(source.token, constants.option.requestType.movement, helpers.species.nameToId(res.data.species))
        .then((res2) => {
          if (helpers.response.isValid(res2.data, setModal)) {
            setProcessingFlags({
              exempt: constants.option.exemption.yes,
              exemptions: res2.data.map((item1) => ({
                ...item1,
                value: flags.find((item2) => item2.processingFlagId === item1.id)
              }))
            });
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            errors.BFF(error, setModal);
          }
        });
    }
  };

  const setPreviousPermissionsAndHolding = () => {
    if (prevPermissions) {
      storeService.session.set.permissions(prevPermissions);
      setPermission(prevPermissions);
    } else {
      storeService.session.set.permissions(userRoleType.GENERIC);
      setPermission(userRoleType.GENERIC);
    }

    if (prevHolding) {
      storeService.session.set.holding(prevHolding);
    } else {
      storeService.session.remove.holding();
    }
  };

  const createBirthList = (applicationItems) => {
    if (applicationItems) {
      return applicationItems.filter((item) => item).map((item) => ({
        tagNumber: get.tagNumber(item.device),
        dob: helpers.date.formatJSDate(item.dateOfBirthRange_Start, helpers.date.is.same(item.dateOfBirthRange_Start, item.dateOfBirthRange_End) ? 'DD/MM/YYYY' : 'YY'),
        breed: item.breed ? item.breed : t('label.notSpecified'),
        genderName: item.gender ? t('recentDetails.' + item.gender) : t('label.notSpecified'),
        geneticDamId: get.tagNumber(item.geneticDam),
        damId: get.tagNumber(item.birthDam),
        sireId: get.tagNumber(item.sire)
      }));
    }

    return [];
  };

  const downloadAnimalList = () => {
    const movementDate = data?.transferDate ? data.transferDate : data?.movementDate;
    const filename = `${data.movementDocument?.movementDocumentRef}-${helpers.date.formatDDMMYYYY(movementDate)}`;
    const devices = data?.movementGroups?.[0]?.devices ? data.movementGroups[0].devices : data?.devices;
    const individualTagsToMap = devices.filter((device) => !Object.values(data?.errors).some((error) => error?.message.includes(device.tagNumber)));
    const individualTagNumbers = individualTagsToMap.map((individualTag) => ({ 'Tag number': individualTag.tagNumber })).sort();
    individualTagNumbers.push({ [t('label.tagNumber')]: `${t('label.totalAnimals')}: ${individualTagNumbers.length}` });
    const batchesToMap = data?.movementGroups?.[0].batches ? data?.movementGroups?.[0].batches : data?.batches;
    const batches = batchesToMap.map((batch) => (
      {
        [t('label.batchNumber')]: batch.batchNumber,
        Quantity: batch.animalTotal
      })
    );

    const totalBatchAnimals = batches.reduce((acc, batch) => acc + batch.Quantity, 0);

    batches.push({ [t('label.batchNumber')]: `${t('label.totalBatches')}: ${batches.length}` });
    batches.push({ [t('label.batchNumber')]: `${t('label.totalAnimals')}: ${totalBatchAnimals}` });

    const tagsNotMoved = [];
    const individualErrorTags = devices.filter((device) => Object.values(data?.errors)
      .some((error) => error.message.includes(device.tagNumber)));
    const tagsNotMovedLabel = helpers.option.requestType.isMovementHandshake(requestType) ? t('label.rejectedIndividualTags') : t('label.tagsNotMoved');

    if (individualErrorTags) {
      tagsNotMoved.push(...individualErrorTags.map((individualErrorTag) => (
        {
          [tagsNotMovedLabel]: individualErrorTag.tagNumber,
          Reason: data?.errors.find((error) => error.message.includes(individualErrorTag.tagNumber)).message
        }
      )
      ));
    }

    const batchesNotMoved = [];

    if (helpers.option.requestType.isMovementHandshake(requestType)) {
      if (data?.reviewMovement?.rejectedDevices) {
        tagsNotMoved.push(...data?.reviewMovement?.rejectedDevices.map((rejectedDevice) => (
          {
            [tagsNotMovedLabel]: rejectedDevice.tagNumber
          }
        )) || []);
      }

      if (data?.reviewMovement?.rejectedBatches) {
        batchesNotMoved.push(...data?.reviewMovement?.rejectedBatches.map((rejectedBatch) => (

          {
            [t('label.rejectedBatchNumbers')]: rejectedBatch.batchNumber,
            Quantity: rejectedBatch.animalTotal
          }
        )) || []);
      }
    } else if (data?.reviewMovement?.rejectedDevices) {
      tagsNotMoved.push(...data?.reviewMovement?.rejectedDevices.map((rejectedDevice) => (
        {
          [tagsNotMovedLabel]: rejectedDevice.tagNumber,
          Reason: t('label.animalRejected')
        }
      )) || []);
    }

    tagsNotMoved.push({ [tagsNotMovedLabel]: `${t('label.totalAnimals')}: ${tagsNotMoved.length}` });

    const animalList = {
      [t('label.individualTagNumbers')]: individualTagNumbers,
      Batches: batches,
      [tagsNotMovedLabel]: tagsNotMoved
    };

    if (helpers.option.requestType.isMovementHandshake(requestType)) {
      batchesNotMoved.push({ [t('label.rejectedBatchNumbers')]: `${t('label.totalBatches')}: ${batchesNotMoved.length}` });
      const totalRejectedBatchAnimals = data?.reviewMovement?.rejectedBatches.reduce((acc, batch) => acc + batch.animalTotal, 0);
      batchesNotMoved.push({ [t('label.rejectedBatchNumbers')]: `${t('label.totalAnimals')}: ${totalRejectedBatchAnimals}` });
      animalList[t('label.rejectedBatchNumbers')] = batchesNotMoved;
    }

    const workbook = helpers.generateWorkbook(animalList);
    helpers.writeWorkbookToFile(workbook, filename, 'ods');
  };

  useEffect(() => {
    const source1 = axios.CancelToken.source();
    const source2 = axios.CancelToken.source();
    const source3 = axios.CancelToken.source();
    const source4 = axios.CancelToken.source();

    if (location.pathname.substring(0, Routing.activityDetails.length) === Routing.activityDetails) {
      setRequestId(location.state.data.requestId);
      setMovementDocumentRef(location.state.data.movementDocumentRef);
      setRequestType(location.state.data.requestType);
      setData(location.state.data);
      setReviewStatus(location.state.data.reviewStatus);

      helpers.get.reviewStatus(source4.token, location.state.data.movementDocumentRef)
        .then((statues) => {
          if (helpers.response.isValid(statues?.data, setModal, setLoadPending)) {
            if (statues?.data?.arrivalReviewStatus?.reviewRequestId) {
              setReviewStatus(statues.data.arrivalReviewStatus.reviewStatusStatus);
            } else {
              setReviewStatus(statues?.data?.departureReviewStatus?.reviewStatusStatus);
            }
          }
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            errors.BFF(error, setModal);
          }
        });

      fetch.activityData(location.state.data, source1, source2, source3);
    }

    return () => {
      source1.cancel();
      source2.cancel();
      source3.cancel();
      source4.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.activityUndo.length) !== Routing.activityUndo) {
        storeService.session.removeAll.confirm();
        unListen();
      }

      if (path.pathname.substring(0, Routing.holdingRegister.length) !== Routing.holdingRegister &&
        path.pathname.substring(0, Routing.userActivity.length) !== Routing.userActivity &&
        path.pathname.substring(0, Routing.activityDetails.length) !== Routing.activityDetails &&
        path.pathname.substring(0, Routing.movementsSummary.length) !== Routing.movementsSummary &&
        path.pathname.substring(0, Routing.abattoirMovements.length) !== Routing.abattoirMovements &&
        path.pathname.substring(0, Routing.assemblyMovements.length) !== Routing.assemblyMovements &&
        path.pathname.substring(0, Routing.collectionMovements.length) !== Routing.collectionMovements &&
        path.pathname.substring(0, Routing.marketMovements.length) !== Routing.marketMovements &&
        path.pathname.substring(0, Routing.showMovements.length) !== Routing.showMovements
      ) {
        storeService.session.removeAll.tableFilters();
        storeService.session.removeAll.searchResults();
        storeService.session.removeAll.confirm();
        unListen();
      }
    });

    window.onpopstate = () => {
      if (storeService.session.get.confirm()) {
        history.push(navigation.userActivity());
      }
    };
  }, [history]);

  const stepPicker = (pathname) => {
    const undoRequestId = storeService.session.get.confirmRequestId();
    const undoRequestType = storeService.session.get.confirmRequestType();
    const pollingStatus = storeService.session.get.confirmPollingStatus();
    const pollingErrors = storeService.session.get.confirmPollingErrors();
    const pollingWarnings = storeService.session.get.confirmPollingWarnings();

    let path;
    if (pathname.substring(0, Routing.activityDetails.length) === Routing.activityDetails) {
      path = 'details';
    } else if (pathname.substring(0, Routing.activityUndo.length) === Routing.activityUndo) {
      path = 'confirm';

      if (!undoRequestId) {
        return <Redirect to={Routing.userActivity} />;
      }
    }

    const statusLabel = (pollingStatus) => {
      switch (pollingStatus) {
        case constants.status.completed_error:
          return content[undoRequestType].failedCancellationLabel;
        case constants.status.pending:
        case constants.status.claPending:
          return `${content[constants.option.requestType.movement].cancellationPending}`;
        default:
          return content[undoRequestType].confirmLabel;
      }
    };

    switch (path) {
      case 'confirm':
        return (
          <Confirmation
            confirm={{
              id: undoRequestId,
              label: content[undoRequestType].reference,
              movementReference: movementDocumentRef
            }}
            content={{
              label: content[undoRequestType].contentSubmission,
              onClick: () => {
                setPermission(userRoleType.GENERIC);
                storeService.session.set.permissions(userRoleType.GENERIC);
                storeService.session.remove.holding();
                history.push(navigation.userActivity());
              }
            }}
            errors={pollingErrors}
            label={statusLabel(pollingStatus)}
            setModal={setModal}
            status={pollingStatus}
            warnings={pollingWarnings}
          />
        );

      case 'details':
      default: {
        const newWarnings = data?.warnings ? helpers.error.consolidate(data.warnings) : null;
        const newErrors = data?.errors ? helpers.error.consolidate(data.errors) : null;
        return (
          <>
            {requestId && requestType &&
              <>
                <Linker
                  label="button.back"
                  linkType="back"
                  onClick={setPreviousPermissionsAndHolding}
                  to={returnUrl}
                />

                <h1 className="h1">{t(content[requestType].title)}: {movementDocumentRef ? movementDocumentRef : requestId}</h1>
                <h2 className="h2">{t(content[requestType].subTitle)}</h2>

                {helpers.option.requestType.isCorrectedMovement(requestType) &&
                  <div className={classes.summaryRow}>
                    <div className={classes.label}>{t(content[requestType].movementRefCorrected)}</div>
                    <div className={classes.value}>
                      {movementDocumentRefCorrected}
                    </div>
                  </div>
                }

                {helpers.option.requestType.isAnimalsAdded(requestType) &&
                  <DateSummary
                    date1={eventDate}
                    label={content[requestType].dateHeader}
                  />
                }

                {!helpers.option.requestType.isAnimalsAdded(requestType) &&
                  <DateSummary
                    date1={helpers.option.movement.isOn(data.movementType) ? departureDate : eventDate}
                    date2={helpers.option.movement.isOn(data.movementType) || helpers.option.movement.isMoveToCommonGround(data.movementType, data.destinationHolding) ? arrivalDate : null}
                    label={content[requestType].dateHeader}
                  />
                }

                {helpers.option.requestType.isMovementHandshake(requestType) && data?.movementDocument &&
                  <DateSummary
                    date1={data?.movementDocument?.departureDetail?.departureDateTime}
                    date2={data?.movementDocument?.destinationDetail?.arrivalDateTime}
                    label={'recentDetails.movementDate'}
                  />
                }

                <div className={classes.summaryRow}>
                  <dt className={classes.label}>{t('recentDetails.submissionStatus')}</dt>

                  {!loadPending &&
                    <div className={classes.value}>
                      <strong className={get.tagClassname(data.status)}>
                        {t('recentDetails.status-' + data.status)}
                      </strong>

                      {(newWarnings?.length > 0 || newErrors?.length > 0) &&
                        <span className={classes.errorText}>
                          {t('recentDetails.seeBelow')}
                        </span>
                      }
                    </div>
                  }
                </div>

                {!helpers.option.requestType.isTagApplication(requestType) &&
                  !helpers.option.requestType.isTagReplacement(requestType) &&
                  !helpers.option.requestType.isAnimalsAdded(requestType) &&
                  !helpers.option.requestType.isBirth(requestType) &&
                  !helpers.option.movement.isPermitMove(data.movementType) &&
                  <AddressSummary
                    info1={helpers.option.requestType.isDeath(requestType) && data?.approvedDisposalRoute ? he.decode(data.approvedDisposalRoute) : (data?.disposalHolding ? he.decode(data.disposalHolding) : data.sourceHolding)}
                    info2={!helpers.option.requestType.isDeath(requestType) ? data.destinationHolding : ''}
                    isManual={helpers.option.requestType.isDeath(requestType) && !data.disposalHolding}
                    label={content[requestType].addressHeader}
                    label1={helpers.option.requestType.isDeath(requestType) ? null : content[requestType].locationFrom}
                    label2={helpers.option.requestType.isDeath(requestType) ? null : content[requestType].locationTo}
                    queryKeeper={true}
                    setModal={setModal}
                  />
                }

                {helpers.option.movement.isPermitMove(data.movementType) &&
                  <AddressSummary
                    info1={data.sourceHolding}
                    info2={t('movements.permitMove.title')}
                    info3={data.comment}
                    isGathering={false}
                    isPermitMove={true}
                    label="label.movementDirection"
                    label1="label.departureHoldingFrom"
                    label2="label.destinationHoldingTo"
                    label3="movements.permitMove.comment.titleKeeperSummary"
                    queryKeeper={true}
                    setModal={setModal}
                  />
                }

                {(
                  helpers.option.requestType.isCorrectedMovement(requestType) ||
                  helpers.option.requestType.isMovement(requestType) ||
                  helpers.option.requestType.isMovementHandshake(requestType) ||
                  (
                    (
                      helpers.option.requestType.isBirth(requestType) ||
                      helpers.option.requestType.isTagApplication(requestType) ||
                      helpers.option.requestType.isTagReplacement(requestType)
                    ) &&
                    data.comment
                  )
                ) && (
                  !helpers.option.movement.isPermitMove(data.movementType)
                ) &&
                  <UserComment
                    comment={data.comment}
                    label="label.userComment"
                  />
                }

                {(
                  helpers.option.requestType.isMovement(requestType) ||
                  helpers.option.requestType.isCorrectedMovement(requestType) ||
                  helpers.option.requestType.isMovementHandshake(requestType)
                ) &&
                  <ExemptionSummary
                    data={processingFlags}
                    label="animalsMoved.summaryLabel"
                  />
                }

                <h2 className="h2">{t('label.animalDetails')}</h2>
                <AnimalSummary
                  batches={batches}
                  batchesRejected={batchesRejected}
                  extraData={extraData}
                  hideAnimalDetails={hideAnimalDetails}
                  individualAnimals={individualAnimals}
                  individualAnimalsRejected={individualAnimalsRejected}
                  noBottomBorder={true}
                  printing={printing}
                  requestType={requestType}
                  setBatches={setBatches}
                  setBatchesRejected={setBatchesRejected}
                  setIndividualAnimals={setIndividualAnimals}
                  setIndividualAnimalsRejected={setIndividualAnimalsRejected}
                  setModal={setModal}
                />

                {(
                  helpers.option.requestType.isMovement(requestType) ||
                  helpers.option.requestType.isCorrectedMovement(requestType)
                ) &&
                  !loadPending &&
                  !helpers.species.isCattleId(speciesId) &&
                  !isMissingOrFound &&
                  <>
                    <TransportSummary
                      label="label.transportInformation"
                      transportInformation={transportInformation}
                    />
                    {typeof fciDetails.recordFCI === 'string' &&
                      <FciSummary
                        animalsData={{
                          batches: batches,
                          animalsBeingMoved: individualAnimals
                        }}
                        collapsible={false}
                        fciData={fciDetails}
                        isActivityDetails={true}
                        numberBeingMoved={individualAnimals?.length + batches?.reduce((total, batch) => total + batch.animalTotal, 0)}
                      />
                    }
                  </>
                }

                {newWarnings?.length > 0 &&
                  <div className="section">
                    <h3 className="h3">{t('recentDetails.warnings')}</h3>
                    <ul>
                      {newWarnings.map((warning, index) => (
                        <li key={index}>{helpers.error.mapping(warning.message, t)}</li>
                      ))}
                    </ul>
                  </div>
                }

                {newErrors?.length > 0 &&
                  <div className="section">
                    <h3 className="h3">{t('recentDetails.errors')}</h3>
                    <ul>
                      {newErrors.map((error, index) => (
                        <li key={index}>{helpers.error.mapping(error.message, t)}</li>
                      ))}
                    </ul>
                  </div>
                }

                {!loadPending &&
                  (
                    helpers.option.requestType.isAnimalsAdded(requestType) ||
                    helpers.option.requestType.isCorrectedMovement(requestType) ||
                    helpers.option.requestType.isMovement(requestType) ||
                    helpers.option.requestType.isMovementHandshake(requestType) ||
                    helpers.option.requestType.isDeath(requestType) ||
                    helpers.option.requestType.isTagApplication(requestType) ||
                    helpers.option.requestType.isTagReplacement(requestType) ||
                    helpers.option.requestType.isBirth(requestType)
                  ) &&
                  <div className={classes.actions}>
                    {!data.isFullUndone &&
                      (
                        helpers.option.requestType.isCorrectedMovement(requestType) ||
                        helpers.option.requestType.isMovement(requestType)
                      ) &&
                      (
                        helpers.species.isCattleId(speciesId) ||
                        (
                          !helpers.species.isCattleId(speciesId) &&
                          data.sourceHolding !== config.UNKNOWN &&
                          data.destinationHolding !== config.UNKNOWN
                        )
                      ) &&
                      <div className={classes.amend}>
                        <Button
                          buttonType="primary"
                          disabled={cancelPending}
                          label={content[requestType].amendButton}
                          onClick={ ((reviewStatus !== undefined) && (reviewStatus === 'ReviewedAndAccepted')) ? () => setConfirmAmend(true) : handleChanges.amendMovement }
                        />
                      </div>
                    }

                    <div className={classes.print}>
                      <Button
                        buttonType="tertiary"
                        label="button.printSummary"
                        onClick={() => {
                          document.body.classList.add('modal-open');
                          setPrinting(true);
                          setTimeout(() => {
                            window.print();
                          }, 100);
                        }}
                      />
                      {
                        (
                          !helpers.option.requestType.isDeath(requestType) &&
                          (
                            helpers.option.requestType.isMovement(requestType) ||
                            helpers.option.requestType.isMovementHandshake(requestType) ||
                            helpers.option.requestType.isCorrectedMovement(requestType)
                          ) &&
                          (
                            helpers.species.isSheepId(speciesId) ||
                            helpers.species.isGoatId(speciesId)
                          ) &&
                          !isMissingOrFound
                        ) &&
                        !bffStatus.isCancelled(data.status) &&
                          <PDFButton
                            buttonType="tertiary"
                            referenceId={movementDocumentRef}
                            requestId={requestId}
                            setModal={setModal}
                          />
                      }
                    </div>
                    {!helpers.option.requestType.isDeath(requestType) &&
                      (
                        helpers.option.requestType.isMovement(requestType) ||
                        helpers.option.requestType.isMovementHandshake(requestType) ||
                        helpers.option.requestType.isCorrectedMovement(requestType)
                      ) &&
                      <div className={classes.download}>
                        <Button
                          buttonType="tertiary"
                          label="button.downloadAnimalList"
                          onClick={downloadAnimalList}
                        />
                      </div>
                    }

                    {!data.isFullUndone &&
                      (
                        helpers.option.requestType.isAnimalsAdded(requestType) ||
                        helpers.option.requestType.isTagApplication(requestType) ||
                        helpers.option.requestType.isTagReplacement(requestType) ||
                        helpers.option.requestType.isBirth(requestType) ||
                        helpers.option.requestType.isMovementHandshake(requestType) ||
                        (
                          (
                            helpers.option.requestType.isMovement(requestType) &&
                            helpers.species.isCattleId(speciesId)
                          ) ||
                          (
                            helpers.option.requestType.isCorrectedMovement(requestType) ||
                            helpers.option.requestType.isMovement(requestType) ||
                            helpers.option.requestType.isDeath(requestType)
                          )
                        )
                      ) &&
                      <div className={classes.cancel}>
                        <Button
                          buttonType="danger"
                          disabled={cancelPending}
                          label={content[requestType].cancelButton}
                          onClick={((reviewStatus !== undefined) && (reviewStatus === 'ReviewedAndAccepted') && (content[requestType].cancelButton !== 'button.cancelMovementReview')) ? () => setConfirmAmend(true) : () => setConfirmUndo(true) }
                        />
                      </div>
                    }
                    <div className={classes.exit}>
                      <Button
                        buttonType="tertiary"
                        disabled={cancelPending}
                        label="button.exit"
                        onClick={() => {
                          setPreviousPermissionsAndHolding();
                          history.push(returnUrl);
                        }}
                      />
                    </div>
                  </div>
                }

                {confirmUndo &&
                  <ModalConfirm
                    id="confirmUndo"
                    modalClose={() => setConfirmUndo(false)}
                    modalConfirm={handleChanges.cancel}
                    modalMessage={content[requestType].cancelPrompt}
                    modalTitle={content[requestType].cancelTitle ? content[requestType].cancelTitle : 'label.cancelMovement'}
                  />
                }
                {confirmAmend &&
                  <ModalConfirm
                    id="confirmAmend"
                    labelCancel="button.close"
                    modalClose={() => setConfirmAmend(false)}
                    modalMessage= {'recentDetails.movementReviewed'}
                    modalTitle= {'recentDetails.titleAmendOrCancel'}
                  />
                }
              </>
            }
          </>
        );
      }
    }
  };

  return (
    <>
      {ready && stepPicker(location.pathname)}
    </>
  );
};

ActivityDetails.propTypes = {
  location: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  setPermission: PropTypes.func.isRequired
};

export default ActivityDetails;

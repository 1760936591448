import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { bffStatus } from 'services/bff';
import axios from 'axios';
import classes from 'backOffice/viewRequestId/viewRequestId.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import AddressSummary from 'backOfficeComponents/summary/addressSummary/addressSummary';
import AnimalSummary from 'components/animalSummary/animalSummary';
import Button from 'backOfficeComponents/base/button/button';
import DateSummary from 'components/summary/dateSummary/dateSummary';
import ExemptionSummary from 'backOfficeComponents/summary/exemptionSummary/exemptionSummary';
import FciSummary from 'backOfficeComponents/summary/fciSummary/fciSummary';
import PageTitle from 'backOfficeComponents/base/pageTitle/pageTitle';
import SectionTitleBar from 'backOfficeComponents/base/sectionTitleBar/sectionTitleBar';
import TransportSummary from 'backOfficeComponents/summary/transportSummary/transportSummary';
import moment from 'moment/moment';
import he from 'he';
const sprintf = require('util').format;

const BoViewRequestId = ({
  location,
  setModal
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const [processingFlags, setProcessingFlags] = React.useState([]);
  const [metFCIWithdrawalPeriods, setMetFCIWithdrawalPeriods] = React.useState([]);
  const [eventDate, setEventDate] = React.useState(null);
  const [hideAnimalDetails, setHideAnimalDetails] = React.useState(false);
  const [batches, setBatches] = React.useState([]);
  const [individualAnimals, setIndividualAnimals] = React.useState([]);
  const [extraData, setExtraData] = React.useState({});

  const data = location.state.data;
  const dataContent = data.content ?? data;
  const requestStatus = !bffStatus.isSuccess(data.requestStatus) ? data.requestStatus : null;
  const isMovement = (
    (
      helpers.option.requestType.isMovement(data.requestTypeId) ||
      helpers.option.requestType.isCorrectedMovement(data.requestTypeId) ||
      helpers.option.requestType.isPaperMovementAdd(data.requestTypeId) ||
      helpers.option.requestType.isPaperMovementEdit(data.requestTypeId)
    ) && (
      dataContent.movementDocument ||
      dataContent.reviewMovement
    )
  ) ||
  helpers.option.requestType.isMovementHandshake(data.requestTypeId);

  let sourceHolding;
  let destinationHolding;
  let departureDate;
  let arrivalDate;
  let totalBatchAnimals;
  let totalBatchAnimalsRejected;
  let totalIndividualAnimals;
  let totalIndividualAnimalsRejected;
  let recordFCI;
  let isPermitMove = false;

  if (isMovement) {
    sourceHolding = dataContent.sourceHolding ?? (dataContent.movementDocument?.departureDetail?.holding ?? dataContent.holding);
    destinationHolding = dataContent.destinationHolding ?? (dataContent.movementDocument?.destinationDetail?.holding ?? null);

    departureDate = dataContent.movementDocument?.departureDetail?.departureDateTime ?? (dataContent.movementDocument?.departureDetail?.departureDate ? helpers.date.toDate(dataContent.movementDocument.departureDetail.departureDate.year, dataContent.movementDocument.departureDetail.departureDate.month, dataContent.movementDocument.departureDetail.departureDate.day) : (dataContent.reviewMovement?.departureDate ?? null));
    arrivalDate = dataContent.movementDocument?.destinationDetail?.arrivalDateTime ?? (dataContent.movementDocument?.destinationDetail?.arrivalDate ? helpers.date.toDate(dataContent.movementDocument.destinationDetail.arrivalDate.year, dataContent.movementDocument.destinationDetail.arrivalDate.month, dataContent.movementDocument.destinationDetail.arrivalDate.day) : (dataContent.reviewMovement?.arrivalDate ?? null));

    if (sourceHolding === destinationHolding || sourceHolding === constants.cph.none || destinationHolding === constants.cph.none) {
      isPermitMove = true;
      destinationHolding = 'movements.permitMove.title';
    }
  }

  if (helpers.option.requestType.isMovement(data.requestTypeId) || helpers.option.requestType.isCorrectedMovement(data.requestTypeId) || helpers.option.requestType.isPaperMovementAdd(data.requestTypeId)) {
    const isAllAnimalsFciCompliant = typeof dataContent.movementDocument?.fciDetail?.isAllAnimalsFciCompliant === 'boolean' || typeof dataContent.movementDocument?.fciDetail?.isAllAnimalsFCICompliant === 'boolean';

    totalBatchAnimals = dataContent.movementGroups?.[0]?.batches?.reduce((total, batch) => total + parseInt(batch.animalTotal), 0) || 0;

    totalIndividualAnimals = dataContent.movementGroups?.[0]?.devices?.length || 0;
    recordFCI = dataContent.movementDocument?.fciDetail && isAllAnimalsFciCompliant ? constants.option.recordFCI.yes : constants.option.recordFCI.no;
  } else if (helpers.option.requestType.isMovementHandshake(data.requestTypeId) && !data.isFullUndone) {
    totalBatchAnimals = dataContent.reviewMovement.acceptedBatches?.reduce((total, batch) => total + parseInt(batch.animalTotal), 0) || 0;
    totalBatchAnimalsRejected = dataContent.reviewMovement.rejectedBatches?.reduce((total, batch) => total + parseInt(batch.animalTotal), 0) || 0;
    totalIndividualAnimals = dataContent.reviewMovement.acceptedDevices?.length || 0;
    totalIndividualAnimalsRejected = dataContent.reviewMovement.rejectedDevices?.length || 0;
  }

  const get = {
    tagNumber: (string) => {
      return string && string.tagNumber ? string.tagNumber : t('label.notSpecified');
    }
  };

  const createBirthList = (applicationItems) => {
    if (applicationItems) {
      return applicationItems.filter((item) => item).map((item) => ({
        tagNumber: get.tagNumber(item.device),
        dob: helpers.date.formatJSDate(item.dateOfBirthRange_Start, helpers.date.is.same(item.dateOfBirthRange_Start, item.dateOfBirthRange_End) ? 'DD/MM/YYYY' : 'YY'),
        breed: item.breed ? item.breed : t('label.notSpecified'),
        genderName: item.gender ? t('recentDetails.' + item.gender) : t('label.notSpecified'),
        geneticDamId: get.tagNumber(item.geneticDam),
        damId: get.tagNumber(item.birthDam),
        sireId: get.tagNumber(item.sire)
      }));
    }

    return [];
  };

  const getRequestTypeLabel = () => {

    const requestTypeLabels = {
      4: 'label.movement',
      10: 'label.tagApplication',
      14: 'label.replacementTags',
      15: 'label.amendedMovement',
      31: 'label.death',
      141: 'label.annualInventoryCreate',
      142: 'label.annualInventoryDelete',
      145: 'label.reviewedMovement',
      165: 'label.birth',
      229: 'label.paperMovement',
      238: 'label.paperMovementEdit',
      240: 'label.adjacentMovementCreate',
      241: 'label.holdingProductionTypeCreate',
      242: 'label.holdingProductionTypeDelete',
      243: 'label.adjacentMovementDelete',
      244: 'label.adjacentMovementEdit',
      246: 'label.animalsAddedToHolding'
    };

    let cancelled;
    let followingAnAmendment;

    if (data.isFullUndone && !helpers.option.requestType.isUndo(data.requestTypeId)) {
      cancelled = t('label.status-Cancelled') + ' ';
    }

    if (dataContent.movementReferenceCorrected) {
      followingAnAmendment = ' ' + t('label.followingAnAmendment');
    }

    const sourceHolding = dataContent.sourceHolding ?? (dataContent.movementDocument?.departureDetail?.holding ?? dataContent.holding);
    const destinationHolding = dataContent.destinationHolding ?? (dataContent.movementDocument?.destinationDetail?.holding ?? null);

    if (isPermitMove) {
      const requestTypeLabel = t('label.permitMove').toLowerCase();
      return cancelled ? cancelled + requestTypeLabel : (requestTypeLabel.charAt(0).toUpperCase() + requestTypeLabel.slice(1));
    }

    if (destinationHolding?.toUpperCase() === constants.cph.unknown) {
      return cancelled ? cancelled + t('menu.missing').toLowerCase() : t('menu.missing');
    }

    if (sourceHolding?.toUpperCase() === constants.cph.unknown) {
      return cancelled ? cancelled + t('menu.found').toLowerCase() : t('menu.found');
    }

    if (Object.keys(requestTypeLabels).includes(data.requestTypeId.toString())) {
      const requestTypeLabel = dataContent.movementReferenceCorrected
        ? t(requestTypeLabels[4]).toLowerCase() + followingAnAmendment
        : t(requestTypeLabels[data.requestTypeId]).toLowerCase();
      return cancelled ? cancelled + requestTypeLabel : (requestTypeLabel.charAt(0).toUpperCase() + requestTypeLabel.slice(1));
    }

    const requestTypeName = storeService.session.get.requestTypes().find((item) => item.id === data.requestTypeId);
    const requestTypeLabel = requestTypeName ? requestTypeName.name.toLowerCase() : '';

    return cancelled ? cancelled + requestTypeLabel : (requestTypeLabel.charAt(0).toUpperCase() + requestTypeLabel.slice(1));
  };

  const navigateToReqId = (reqId) => {
    storeService.session.set.tableFiltersRequestId({
      requestId: reqId,
      newRequest: true
    });
    history.push(Routing.boSearchRequestId);
  };

  const requestHasData = () => {
    if (isMovement) {
      return (
        Boolean(
          data.receivedDate ||
          dataContent.movementDocument ||
          dataContent.movementReferenceUndone ||
          dataContent.movementReferenceCorrected ||
          dataContent.sourceHolding ||
          dataContent.destinationHolding ||
          dataContent.movementGroups?.[0]?.batches.length > 0 ||
          dataContent.movementGroups?.[0]?.devices.length > 0 ||
          dataContent.reviewMovement?.departureDate ||
          dataContent.reviewMovement?.arrivalDate ||
          dataContent.reviewMovement?.rejectedBatches.length > 0 ||
          dataContent.reviewMovement?.rejectedDevices.length > 0
        )
      );
    }

    if (
      helpers.option.requestType.isTagApplication(data.requestTypeId) ||
      helpers.option.requestType.isTagReplacement(data.requestTypeId) ||
      helpers.option.requestType.isBirth(data.requestTypeId)
    ) {
      return (
        Boolean(
          eventDate ||
          dataContent.holding ||
          dataContent.comment ||
          extraData.dob ||
          extraData.breed ||
          extraData.gender ||
          extraData.genotype ||
          batches?.length > 0 ||
          individualAnimals?.length > 0
        )
      );
    }

    if (helpers.option.requestType.isDeath(data.requestTypeId)) {
      return (
        Boolean(
          eventDate ||
          dataContent.disposalDate ||
          dataContent.holding ||
          dataContent.disposalHolding ||
          dataContent.comment ||
          batches?.length > 0 ||
          individualAnimals?.length > 0
        )
      );
    }

    if (helpers.option.requestType.isAnimalsAdded(data.requestTypeId)) {
      return (
        Boolean(
          dataContent.dateAnimalsWereLocated ||
          dataContent.holding ||
          individualAnimals?.length > 0
        )
      );
    }

    if (
      helpers.option.requestType.isAdjacentMovement(data.requestTypeId) ||
      helpers.option.requestType.isAnnualInventoryCreate(data.requestTypeId) ||
      helpers.option.requestType.isAnnualInventoryDelete(data.requestTypeId) ||
      helpers.option.requestType.isHoldingProductionTypeCreate(data.requestTypeId) ||
      helpers.option.requestType.isHoldingProductionTypeDelete(data.requestTypeId)
    ) {
      return Boolean(dataContent.requestDate);
    }

    return false;
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const source = axios.CancelToken.source();

    if (
      !data.isFullUndone &&
      dataContent.species &&
      (
        helpers.option.requestType.isMovement(data.requestTypeId) ||
        helpers.option.requestType.isCorrectedMovement(data.requestTypeId) ||
        helpers.option.requestType.isMovementHandshake(data.requestTypeId) ||
        helpers.option.requestType.isPaperMovementAdd(data.requestTypeId) ||
        helpers.option.requestType.isPaperMovementEdit(data.requestTypeId)
      )
    ) {
      helpers.get.processingFlags(source.token, constants.option.requestType.movement, helpers.species.nameToId(dataContent.species))
        .then((r) => {
          const processingFlags = dataContent.processingFlags ?? data.processingFlags;

          if (helpers.response.isValid(r.data, setModal)) {
            setProcessingFlags(processingFlags.map((item) => ({
              id: item.processingFlagId,
              label: helpers.extract.processingLabel(r.data, item.processingFlagId),
              name: helpers.extract.processingName(r.data, item.processingFlagId),
              value: true
            })));
          }
        });

      helpers.get.metFciWithdrawalPeriods(source.token)
        .then((r) => {
          if (helpers.response.isValid(r.data, setModal)) {
            setMetFCIWithdrawalPeriods(r.data);
          }
        });
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    let tempBatches = [];
    let tempIndividualAnimals = [];
    let dob;
    let isSheepOrGoats;

    switch (data.requestTypeId) {
      case constants.option.requestType.animalsAdded:
        setEventDate(dataContent.dateAnimalsWereLocated);
        tempIndividualAnimals = helpers.animal.addDobAndAgeForDisplay(
          dataContent?.devicesWithDetail?.map((animal) => ({
            ...animal,
            tagNumber: animal.device.tagNumber,
            breedName: animal.breed,
            genderName: animal.gender
          }))
        );
        setIndividualAnimals(tempIndividualAnimals);
        break;

      case constants.option.requestType.birth:
        setEventDate(dataContent.dateOfApplication);
        tempBatches = [];
        tempIndividualAnimals = createBirthList(dataContent.applicationItems);

        setBatches(tempBatches);
        setIndividualAnimals(tempIndividualAnimals.map((item) => ({
          ...item,
          genotype: dataContent.genotype
        })));
        break;

      case constants.option.requestType.death:
        setEventDate(dataContent.killDate);
        if (dataContent.deathofUntaggedAnimals && dataContent.deathofUntaggedAnimals.animalTotal > 0) {
          setHideAnimalDetails(true);
        } else {
          tempBatches = dataContent.batchDetails;
        }
        tempIndividualAnimals = dataContent.killedDevices;

        setBatches(tempBatches);
        setIndividualAnimals(tempIndividualAnimals);
        break;

      case constants.option.requestType.tagApplication:
        isSheepOrGoats = dataContent?.species?.toLowerCase() === constants.species.animalTypes.SHEEP || dataContent?.species?.toLowerCase() === constants.species.animalTypes.GOATS;
        tempBatches = dataContent?.batches;
        tempIndividualAnimals = dataContent?.devices;
        dob = isSheepOrGoats && dataContent?.dateOfBirthRangeStart ? moment(dataContent.dateOfBirthRangeStart).year() : (dataContent?.dateOfBirthRangeStart ? helpers.date.format(dataContent.dateOfBirthRangeStart) : null);

        setEventDate(dataContent?.dateOfApplication);
        setExtraData({
          dob,
          breed: dataContent?.breed,
          gender: dataContent?.gender,
          genotype: dataContent?.genotype
        });

        setBatches(tempBatches);
        setIndividualAnimals(tempIndividualAnimals);
        break;

      case constants.option.requestType.tagReplacement:
        setEventDate(dataContent.replacementDate);
        dataContent.replaceItems.forEach((item) => {
          if (item.batchReplacing) {
            tempBatches.push({
              animalTotal: item.batchReplacing.animalTotal,
              oldBatchNumber: item.batchReplaced ? item.batchReplaced.batchNumber : t('label.unknown'),
              replacedBy: t('tagging.replacement.replacedBy'),
              newBatchNumber: item.batchReplacing.batchNumber
            });
          } else if (item.deviceReplacing) {
            if (item.batchReplaced) {
              tempIndividualAnimals.push({
                oldTagNumber: item.batchReplaced.batchNumber,
                newTagNumber: item.deviceReplacing.tagNumber
              });
            } else if (item.deviceReplaced) {
              tempIndividualAnimals.push({
                oldTagNumber: item.deviceReplaced.tagNumber,
                newTagNumber: item.deviceReplacing.tagNumber
              });
            } else {
              tempIndividualAnimals.push({
                oldTagNumber: constants.unknown,
                newTagNumber: item.deviceReplacing.tagNumber
              });
            }
          }

          setBatches(tempBatches);
          setIndividualAnimals(tempIndividualAnimals);
        });
        break;

      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ready && data &&
        <>
          <PageTitle
            id="viewRequestIdTitle"
            pageTitleOne="boApp.pageTitles.requestId.requestId"
            pageTitleTwo={sprintf(
              '%s - %s',
              data.requestId,
              getRequestTypeLabel()
            )}
            speciesId={helpers.species.nameToId(dataContent.species)}
          />

          <div className="pageContent">
            {(data.isFullUndone || !requestHasData()) &&
              <>
                {data.isFullUndone &&
                  <div className={classes.summaryRow}>
                    <div className={classes.label}>{t('label.isUndone')}</div>
                    <div className={classes.value}>{t('label.yes')}</div>
                  </div>
                }

                {data.requestDate && !requestHasData() &&
                  <div className={classes.summaryRow}>
                    <div className={classes.label}>{t('label.reportedDate')}</div>
                    <div className={classes.value}>{helpers.date.format(data.requestDate)}</div>
                  </div>
                }

                {data.userName && !requestHasData() &&
                  <div className={[classes.summaryRow, data.undoRequestId ? null : classes.noBottomBorder].join(' ').trim()}>
                    <div className={classes.label}>{t('label.username')}</div>
                    <div className={classes.value}>{data.userName}</div>
                  </div>
                }

                {data.undoRequestId &&
                  <div className={[classes.summaryRow, classes.noBottomBorder].join(' ')}>
                    <div className={classes.label}>{data.errors.length === 0 ? t('label.undoneRequestId') : t('label.unsuccessfulUndoRequestId')}</div>
                    <div className={classes.value}>
                      <Button
                        buttonType="link"
                        id="undoRequestId"
                        label={data.undoRequestId.toString()}
                        onClick={() => navigateToReqId(data.undoRequestId)}
                      />
                    </div>
                  </div>
                }
              </>
            }

            {requestHasData() &&
              <>
                {isMovement &&
                  <>
                    {(!sourceHolding || !destinationHolding || !departureDate) &&
                      <div className={[classes.summaryRow, classes.noBottomBorder].join(' ')}>{t('label.originalPaperMovementDeleted')}</div>
                    }

                    <SectionTitleBar
                      icon="bi bi-arrows-move"
                      id="movementDetailsSectionTitle"
                      status={data.isFullUndone || requestStatus ? {
                        className: requestStatus,
                        label: 'boApp.sectionTitles.status.' + ((data.isFullUndone && !requestStatus) || (data.isFullUndone && requestStatus) ? 'Cancelled' : requestStatus),
                        hint: data.isFullUndone && !requestStatus ? null : 'boApp.sectionTitles.statusHelp.' + requestStatus
                      } : null}
                      title="boApp.sectionTitles.movementDetails"
                    />

                    {data.requestDate &&
                      <div className={classes.summaryRow}>
                        <div className={classes.label}>{t('label.reportedDate')}</div>
                        <div className={classes.value}>{helpers.date.format(data.requestDate)}</div>
                      </div>
                    }

                    {data.userName &&
                      <div className={classes.summaryRow}>
                        <div className={classes.label}>{t('label.username')}</div>
                        <div className={classes.value}>{data.userName}</div>
                      </div>
                    }

                    {data.receivedDate && !helpers.option.requestType.isMovementHandshake(data.requestTypeId) &&
                      <div className={classes.summaryRow}>
                        <div className={classes.label}>{t('label.documentReceivedByBackOffice')}</div>
                        <div className={classes.value}>{helpers.date.format(data.receivedDate)}</div>
                      </div>
                    }

                    {dataContent.movementDocument?.movementDocumentRef &&
                      <div className={classes.summaryRow}>
                        <div className={classes.label}>{t('label.movementRef')}</div>
                        <div className={classes.value}>{dataContent.movementDocument.movementDocumentRef}</div>
                      </div>
                    }

                    {dataContent.movementReferenceUndone &&
                      <div className={classes.summaryRow}>
                        <div className={classes.label}>{t('label.originalMovementReference')}</div>
                        <div className={classes.value}>{dataContent.movementReferenceUndone}</div>
                      </div>
                    }

                    {dataContent.movementReferenceCorrected &&
                      <div className={classes.summaryRow}>
                        <div className={classes.label}>{t('label.movementRefNew')}</div>
                        <div className={classes.value}>{dataContent.movementReferenceCorrected}</div>
                      </div>
                    }

                    {(departureDate || arrivalDate) &&
                      <DateSummary
                        date1={departureDate}
                        date2={arrivalDate}
                        label="label.movementDates"
                      />
                    }

                    {(sourceHolding || destinationHolding) &&
                      <AddressSummary
                        info1={sourceHolding}
                        info2={destinationHolding}
                        info3={dataContent.comment}
                        info3isComment={true}
                        isPermitMove={isPermitMove}
                        label="label.movementDirection"
                        label1="label.departureHoldingFrom"
                        label2="label.destinationHoldingTo"
                        label3="label.comments"
                        setModal={setModal}
                      />
                    }

                    {!helpers.option.requestType.isMovementHandshake(data.requestTypeId) &&
                      <>
                        <SectionTitleBar
                          icon="bi bi-exclamation-triangle"
                          id="exemptionsSectionTitle"
                          title="boApp.sectionTitles.exemptions"
                        />
                        <ExemptionSummary
                          data={{
                            exempt: dataContent.processingFlags.length > 0 ? constants.option.exemption.yes : constants.option.exemption.no,
                            exemptions: processingFlags
                          }}
                          label="movementDetails.searchResults.typeExemptions"
                        />
                      </>
                    }

                    {(dataContent.movementGroups?.[0]?.batches || dataContent.movementGroups?.[0]?.devices || dataContent.reviewMovement.acceptedBatches || dataContent.reviewMovement.acceptedDevices) &&
                      <>
                        <SectionTitleBar
                          icon="bi bi-list-ol"
                          id="animalDetailsSectionTitle"
                          title="movementDetails.searchResults.animalDetails"
                        />
                        <AnimalSummary
                          batches={dataContent.movementGroups?.[0]?.batches ? helpers.extract.batches(dataContent) : dataContent.reviewMovement.acceptedBatches}
                          individualAnimals={dataContent.movementGroups?.[0]?.devices ? helpers.extract.devices(dataContent) : dataContent.reviewMovement.acceptedDevices}
                          noBottomBorder={true}
                          requestType={data.requestTypeId}
                          setModal={setModal}
                          totalAnimals={dataContent.animalCount}
                        />
                      </>
                    }

                    {helpers.option.requestType.isMovementHandshake(data.requestTypeId) &&
                      (dataContent.reviewMovement?.rejectedBatches || dataContent.reviewMovement?.rejectedDevices) &&
                      <AnimalSummary
                        batchesRejected={dataContent.reviewMovement.rejectedBatches}
                        individualAnimalsRejected={dataContent.reviewMovement.rejectedDevices}
                        noBottomBorder={true}
                        requestType={data.requestTypeId}
                        setModal={setModal}
                        totalAnimals={totalBatchAnimalsRejected + totalIndividualAnimalsRejected}
                      />
                    }

                    {!helpers.option.requestType.isMovementHandshake(data.requestTypeId) &&
                      <>
                        <SectionTitleBar
                          icon="bi bi-info-circle"
                          id="fciSectionTitle"
                          title="boApp.sectionTitles.fci"
                        />
                        <FciSummary
                          animalsData={{
                            batches: dataContent.movementGroups?.[0]?.batches,
                            animalsBeingMoved: dataContent.movementGroups?.[0]?.devices
                          }}
                          fciData={{
                            recordFCI,
                            animalsSatisfy: recordFCI === constants.option.recordFCI.yes ? helpers.extract.animalsSatisfy(dataContent.movementDocument.fciDetail) : null,
                            holdingRestrictions: dataContent.movementDocument?.fciDetail?.holdingRestrictions,
                            id: dataContent.movementDocument?.fciDetail?.id, // Not currently used
                            nonComplianceReason: dataContent.movementDocument?.fciDetail?.nonCompliantReason,
                            withdrawalPeriod: helpers.extract.withdrawalPeriod(dataContent.movementDocument?.fciDetail, metFCIWithdrawalPeriods),
                            unfitAnimals: dataContent.movementDocument?.fciDetail?.nonCompliantDevices
                          }}
                          numberBeingMoved={totalBatchAnimals + totalIndividualAnimals}
                        />
                      </>
                    }

                    {dataContent.movementDocument?.transportDetail &&
                      <>
                        <SectionTitleBar
                          icon="bi bi-truck"
                          id="transportSummarySectionTitle"
                          title="boApp.sectionTitles.transportDetails"
                        />
                        <TransportSummary
                          label="movementDetails.searchResults.transportInformation"
                          transportInformation={{
                            contactPhone: dataContent.movementDocument?.transportDetail?.transporter?.telephoneNumber,
                            durationOfJourney: dataContent.movementDocument?.departureDetail?.expectedDurationOfJourney,
                            recordTransportInformation: dataContent.movementDocument?.transportDetail?.transporterType ? constants.option.recordTransportInformation.yes : constants.option.recordTransportInformation.no,
                            transportedBy: helpers.lookup.transporterType(dataContent.movementDocument?.transportDetail?.transporterType),
                            vehicleRegistrationNumber: dataContent.movementDocument?.transportDetail?.transportVehicleRegistrationNo
                          }}
                        />
                      </>
                    }
                  </>
                }

                {(
                  helpers.option.requestType.isTagApplication(data.requestTypeId) ||
                  helpers.option.requestType.isTagReplacement(data.requestTypeId) ||
                  helpers.option.requestType.isBirth(data.requestTypeId) ||
                  helpers.option.requestType.isDeath(data.requestTypeId) ||
                  helpers.option.requestType.isAnimalsAdded(data.requestTypeId)
                ) &&
                  <>
                    <SectionTitleBar
                      icon="bi bi-arrows-move"
                      id="movementDetailsSectionTitle"
                      status={data.isFullUndone || requestStatus ? {
                        className: requestStatus,
                        label: 'boApp.sectionTitles.status.' + ((data.isFullUndone && !requestStatus) || (data.isFullUndone && requestStatus) ? 'Cancelled' : requestStatus),
                        hint: data.isFullUndone && !requestStatus ? null : 'boApp.sectionTitles.statusHelp.' + requestStatus
                      } : null}
                      title={helpers.option.requestType.isTagApplication(data.requestTypeId)
                        ? 'boApp.sectionTitles.applicationDetails'
                        : (helpers.option.requestType.isTagReplacement(data.requestTypeId)
                          ? 'boApp.sectionTitles.replacementDetails'
                          : (helpers.option.requestType.isBirth(data.requestTypeId)
                            ? 'boApp.sectionTitles.birthDetails'
                            : (helpers.option.requestType.isDeath(data.requestTypeId)
                              ? 'boApp.sectionTitles.deathDetails'
                              : 'boApp.sectionTitles.animalsAddedDetails'
                            )
                          )
                        )
                      }
                    />

                    {data.requestDate &&
                      <div className={classes.summaryRow}>
                        <div className={classes.label}>{t('label.reportedDate')}</div>
                        <div className={classes.value}>{helpers.date.format(data.requestDate)}</div>
                      </div>
                    }

                    {data.userName &&
                      <div className={classes.summaryRow}>
                        <div className={classes.label}>{t('label.username')}</div>
                        <div className={classes.value}>{data.userName}</div>
                      </div>
                    }

                    {eventDate &&
                      <DateSummary
                        date1={eventDate}
                        label={helpers.option.requestType.isTagApplication(data.requestTypeId) || helpers.option.requestType.isBirth(data.requestTypeId)
                          ? 'label.dateOfTagging'
                          : (helpers.option.requestType.isTagReplacement(data.requestTypeId)
                            ? 'label.dateOfReplacement'
                            : (helpers.option.requestType.isDeath(data.requestTypeId)
                              ? 'label.dateOfDeath'
                              : 'label.dateOfSighting'
                            )
                          )
                        }
                      />
                    }

                    {dataContent.holding &&
                      <AddressSummary
                        info1={dataContent.holding}
                        label={helpers.option.requestType.isDeath(data.requestTypeId)
                          ? 'label.holdingOfDeath'
                          : (helpers.option.requestType.isAnimalsAdded(data.requestTypeId)
                            ? 'label.sightingCph'
                            : 'label.holdingOfTagging'
                          )
                        }
                        setModal={setModal}
                        showBorder={Boolean(dataContent.comment || dataContent.disposalHolding || dataContent.approvedDisposalRoute)}
                      />
                    }

                    {dataContent.disposalHolding &&
                      <AddressSummary
                        info1={dataContent.disposalHolding}
                        label="label.carcassLocation"
                        setModal={setModal}
                        showBorder={Boolean(dataContent.comment || dataContent.approvedDisposalRoute)}
                      />
                    }

                    {dataContent.approvedDisposalRoute &&
                      <div className={classes.summaryRow}>
                        <div className={classes.label}>{t('label.carcassLocation')}</div>
                        <div className={classes.value}>{dataContent.approvedDisposalRoute}</div>
                      </div>
                    }

                    {dataContent.comment &&
                      <div className={classes.summaryRow}>
                        <div className={classes.label}>{t('label.comment')}</div>
                        <div className={classes.value}>{dataContent.comment}</div>
                      </div>
                    }

                    {(batches?.length > 0 || individualAnimals?.length > 0) &&
                      <>
                        <SectionTitleBar
                          icon="bi bi-list-ol"
                          id="animalDetailsSectionTitle"
                          title="movementDetails.searchResults.animalDetails"
                        />
                        {!helpers.option.requestType.isBirth(data.requestTypeId) && extraData.dob &&
                          <div className={classes.summaryRow}>
                            <div className={classes.label}>{extraData.dob.toString().length === 4 ? t('label.yearOfBirth') : t('recentDetails.dateOfBirth')}</div>
                            <div className={classes.count}>{extraData.dob}</div>
                          </div>
                        }
                        {!helpers.option.requestType.isBirth(data.requestTypeId) && extraData.breed &&
                          <div className={classes.summaryRow}>
                            <div className={classes.label}>{t('recentDetails.breed')}</div>
                            <div className={classes.count}>{extraData.breed}</div>
                          </div>
                        }
                        {!helpers.option.requestType.isBirth(data.requestTypeId) && extraData.gender &&
                          <div className={classes.summaryRow}>
                            <div className={classes.label}>{t('recentDetails.gender')}</div>
                            <div className={classes.count}>{t('recentDetails.' + extraData.gender)}</div>
                          </div>
                        }
                        {!helpers.option.requestType.isBirth(data.requestTypeId) && extraData.genotype &&
                          <div className={classes.summaryRow}>
                            <div className={classes.label}>{t('recentDetails.genotype')}</div>
                            <div className={classes.count}>{extraData?.genotype ? he.decode(extraData.genotype) : ''}</div>
                          </div>
                        }
                        <AnimalSummary
                          batches={batches}
                          hideAnimalDetails={hideAnimalDetails}
                          individualAnimals={individualAnimals}
                          noBottomBorder={true}
                          requestType={data.requestTypeId}
                          setModal={setModal}
                        />
                      </>
                    }
                  </>
                }

                {(
                  helpers.option.requestType.isAdjacentMovement(data.requestTypeId) ||
                  helpers.option.requestType.isAnnualInventoryCreate(data.requestTypeId) ||
                  helpers.option.requestType.isAnnualInventoryDelete(data.requestTypeId) ||
                  helpers.option.requestType.isHoldingProductionTypeCreate(data.requestTypeId) ||
                  helpers.option.requestType.isHoldingProductionTypeDelete(data.requestTypeId)
                ) &&
                  <>
                    <div className={classes.summaryRow}>
                      <div className={classes.label}>{t(helpers.option.requestType.isAnnualInventoryDelete(data.requestTypeId) || helpers.option.requestType.isHoldingProductionTypeDelete(data.requestTypeId) ? 'label.dateDeleted' : 'label.reportedDate')}</div>
                      <div className={classes.value}>{helpers.date.format(data.requestDate)}</div>
                    </div>
                    <div className={[classes.summaryRow, classes.noBottomBorder].join(' ')}>
                      <div className={classes.label}>{t('label.username')}</div>
                      <div className={classes.value}>{data.userName}</div>
                    </div>
                  </>
                }
              </>
            }

            {(data.errors?.length > 0 || data.warnings?.length > 0) &&
              <>
                <SectionTitleBar
                  icon="bi bi-exclamation-triangle"
                  id="errorsAndWarningsSectionTitle"
                  title="boApp.sectionTitles.errorsAndWarnings"
                />

                {data.errors?.length > 0 &&
                  <div className={[classes.status, classes.noBottomBorder, data.warnings?.length > 0 ? classes.marginBottom : null].join(' ').trim()}>
                    <div className={classes.title}>{t('label.errors')}</div>
                    {helpers.error.consolidate(data.errors).map((itemValue, itemKey) => (
                      <div className={classes.code} key={itemKey}>{itemValue.code + ' - ' + itemValue.message}</div>)
                    )}
                  </div>
                }

                {data.warnings?.length > 0 &&
                  <div className={[classes.status, classes.noBottomBorder].join(' ')}>
                    <div className={classes.title}>{t('label.warnings')}</div>
                    {helpers.error.consolidate(data.warnings).map((itemValue, itemKey) => (
                      <div className={classes.code} key={itemKey}>{itemValue.code + ' - ' + itemValue.message}</div>)
                    )}
                  </div>
                }
              </>
            }

            <div className="actions">
              <div className="left" />
              <div className="right">
                <Button
                  buttonType="tertiary"
                  id="returnButton"
                  label="button.return"
                  onClick={() => history.push(Routing.boSearchRequestId)}
                />
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};

BoViewRequestId.propTypes = {
  location: PropTypes.object,
  setModal: PropTypes.func.isRequired
};

export default BoViewRequestId;

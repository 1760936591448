import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'services/serviceWorker';
import initI18N from 'services/i18n';
import msalInstance from 'msalClient';
import App from 'app';
import storeService from 'services/storeService';
import codeHelper from 'services/codeHelper';
import Hotjar from '@hotjar/browser';

initI18N();
const cookiesEnabled = (storeService.cookie.get.cookieConsent() === '2');
if (cookiesEnabled && codeHelper.HOTJAR_SITE_ID && codeHelper.HOTJAR_SITE_ID > 0) {
  Hotjar.init(codeHelper.HOTJAR_SITE_ID, 6);
}
ReactDOM.render(
  <React.StrictMode>
    <App msalInstance={msalInstance} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

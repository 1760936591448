import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'frontEnd/movementsGatherings/foodChainInformation/foodChainInformation.module.scss';
import config from 'config';
import constants from 'services/constants';
import he from 'he';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import TextEntry from 'components/base/textEntry/textEntry';
import FoodChainInformationHelper from 'components/foodChainInformationHelper/foodChainInformationHelper';

const FoodChainInformation = ({
  animals,
  batchList,
  choiceDisabled,
  disabledIcon,
  fciData,
  metFCIWithdrawalPeriods,
  panelTitle,
  setAnimals,
  setDataChanged,
  setFciData,
  setModal,
  speciesId
}) => {
  const { ready } = useTranslation();

  const [recordFCI, setRecordFCI] = React.useState(choiceDisabled ? null : constants.option.recordFCI.no);
  const [withdrawalPeriod, setWithdrawalPeriod] = React.useState(null);
  const [doAnimalsSatisfy, setDoAnimalsSatisfy] = React.useState(constants.option.animalsSatisfy.doSatisfy);
  const [unfitAnimalTagNumbers, setUnfitAnimalTagNumbers] = React.useState([]);
  const [nonComplianceReason, setNonComplianceReason] = React.useState();
  const [holdingRestrictions, setHoldingRestrictions] = React.useState();

  const paginationParams = constants.pagination.defaultParams;
  paginationParams[constants.pagination.bottom].total = animals?.length;

  const dataHasChanged = () => {
    storeService.session.set.dataChanged(true);
    setDataChanged(true);
  };

  const handleChanges = {

    changeHoldingRestrictions: (event) => {
      storeService.session.set.movementFciHoldingRestrictions(event.target.value);
      setHoldingRestrictions(event.target.value);

      setFciData((prevState) => ({
        ...prevState,
        holdingRestrictions: event.target.value
      }));

      dataHasChanged();
    },

    nonComplianceReason: (event) => {
      storeService.session.set.movementFciNonComplianceReason(event.target.value);
      setNonComplianceReason(event.target.value);

      setFciData((prevState) => ({
        ...prevState,
        nonComplianceReason: event.target.value
      }));

      dataHasChanged();
    }
  };

  useEffect(() => {
    const sessionRecordFCI = storeService.session.get.movementFciRecordFci();

    if (sessionRecordFCI) {
      setRecordFCI(sessionRecordFCI);

      if (helpers.option.fci.doRecord(sessionRecordFCI)) {
        const sessionAnimalsSatisfy = storeService.session.get.movementFciAnimalsSatisfy();
        if (sessionAnimalsSatisfy) {
          setDoAnimalsSatisfy(sessionAnimalsSatisfy);

          if (helpers.option.animals.doNotSatisfy(sessionAnimalsSatisfy)) {
            const sessionUnfitAnimals = storeService.session.get.movementFciUnfitAnimals();
            const sessionNonComplianceReason = storeService.session.get.movementFciNonComplianceReason();
            const sessionWithdrawalPeriod = storeService.session.get.movementFciWithdrawalPeriod();
            const sessionHoldingRestrictions = storeService.session.get.movementFciHoldingRestrictions();

            if (sessionUnfitAnimals.length > 0) {
              setUnfitAnimalTagNumbers(sessionUnfitAnimals);
            }
            if (sessionNonComplianceReason) {
              setNonComplianceReason(sessionNonComplianceReason);
            }
            if (sessionWithdrawalPeriod) {
              setWithdrawalPeriod(sessionWithdrawalPeriod);
            }
            if (sessionHoldingRestrictions) {
              setHoldingRestrictions(sessionHoldingRestrictions);
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWithdrawalPeriod(fciData.withdrawalPeriod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fciData.withdrawalPeriod]);

  return (
    <>
      {ready &&
        <details className="accordion">
          <FoodChainInformationHelper
            animals={animals}
            batchList={batchList}
            choiceDisabled={choiceDisabled}
            disabledIcon={disabledIcon}
            doAnimalsSatisfy={doAnimalsSatisfy}
            fciData={fciData}
            metFCIWithdrawalPeriods={metFCIWithdrawalPeriods}
            paginationParams={paginationParams}
            panelTitle={panelTitle}
            recordFCI={recordFCI}
            setAnimals={setAnimals}
            setDataChanged={setDataChanged}
            setDoAnimalsSatisfy={setDoAnimalsSatisfy}
            setFciData={setFciData}
            setHoldingRestrictions={setHoldingRestrictions}
            setModal={setModal}
            setNonComplianceReason={setNonComplianceReason}
            setRecordFCI={setRecordFCI}
            setUnfitAnimalTagNumbers={setUnfitAnimalTagNumbers}
            setWithdrawalPeriod={setWithdrawalPeriod}
            speciesId={speciesId}
            unfitAnimalTagNumbers={unfitAnimalTagNumbers}
            withdrawalPeriod={withdrawalPeriod}
          />
          <div>
            {recordFCI === constants.option.recordFCI.yes &&
              <>
                {doAnimalsSatisfy === constants.option.animalsSatisfy.doNotSatisfy &&
                  <>
                    <div className={classes.foodChainInformation}>
                      <div className={classes.input}>
                        <TextEntry
                          hint="movements.fci-information.input-field-one-hint"
                          id="fciNonCompliance"
                          label="movements.fci-information.input-field-one"
                          maxLength={255}
                          name="fciNonCompliance"
                          onChange={handleChanges.nonComplianceReason}
                          rows={config.ROWS_FCI_NON_COMPLIANCE}
                          tooltip="movements.fci-information.input-field-one-hint"
                          value={nonComplianceReason ? he.decode(nonComplianceReason) : ''}
                          width="full"
                        />
                      </div>

                      <div className={classes.input}>
                        <TextEntry
                          hint="movements.fci-information.character-limit-255-hint"
                          id="holdingRestrictions"
                          label={helpers.species.isDeerId(speciesId) ? 'movements.fci-information.input-field-three-deer' : 'movements.fci-information.input-field-three-sheepAndGoats'}
                          maxLength={255}
                          name="holdingRestrictions"
                          onChange={handleChanges.changeHoldingRestrictions}
                          rows={config.ROWS_HOLDING_RESTRICTIONS}
                          value={holdingRestrictions ? he.decode(holdingRestrictions) : ''}
                          width="full"
                        />
                      </div>
                    </div>
                  </>
                }
              </>
            }
          </div>
        </details>
      }
    </>
  );
};

FoodChainInformation.propTypes = {
  animals: PropTypes.array,
  batchList: PropTypes.array,
  choiceDisabled: PropTypes.bool,
  disabledIcon: PropTypes.bool,
  fciData: PropTypes.object,
  panelTitle: PropTypes.string,
  setAnimals: PropTypes.func,
  setDataChanged: PropTypes.func,
  setFciData: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  speciesId: PropTypes.number
};

export default FoodChainInformation;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/sections/foodChainInformation/foodChainInformation.module.scss';
import config from 'config';
import constants from 'services/constants';
import he from 'he';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import FieldEntry from 'backOfficeComponents/base/fieldEntry/fieldEntry';
import FoodChainInformationHelper from 'components/foodChainInformationHelper/foodChainInformationHelper';

const FoodChainInformation = ({
  animals,
  batchList,
  choiceDisabled,
  disabledIcon = false,
  fciData,
  holdingRestrictions,
  metFCIWithdrawalPeriods,
  nonComplianceReason,
  panelTitle,
  setAnimalDetails,
  setDataChanged,
  setFciData,
  setHoldingRestrictions,
  setModal,
  setNonComplianceReason,
  speciesId
}) => {
  const { ready } = useTranslation();

  const [recordFCIBO, setRecordFCIBO] = React.useState(choiceDisabled ? null : constants.option.recordFCI.no);
  const [withdrawalPeriod, setWithdrawalPeriod] = React.useState(null);
  const [doAnimalsSatisfyBO, setDoAnimalsSatisfyBO] = React.useState(constants.option.animalsSatisfy.doSatisfy);
  const [unfitAnimalTagNumbers, setUnfitAnimalTagNumbers] = React.useState([]);
  const paginationParamsBO = constants.pagination.defaultParams;

  paginationParamsBO[constants.pagination.bottom].total = animals?.length;

  const validateTextFields = (input, characterLimit) => {
    return input.length <= characterLimit;
  };

  const dataHasChanged = () => {
    storeService.session.set.dataChanged(true);
    setDataChanged(true);
  };

  const handleChanges = {

    changeHoldingRestrictions: (event) => {
      if (validateTextFields(event.target.value, config.LENGTH_HOLDING_RESTRICTIONS)) {
        setHoldingRestrictions(event.target.value);
        setFciData((prevState) => ({
          ...prevState,
          holdingRestrictions: event.target.value
        }));
      }

      dataHasChanged();
    },

    nonComplianceReason: (event) => {
      if (validateTextFields(event.target.value, config.LENGTH_NON_COMPLIANCE_REASON)) {
        setNonComplianceReason(event.target.value);
        setFciData((prevState) => ({
          ...prevState,
          nonComplianceReason: event.target.value
        }));
      }

      dataHasChanged();
    }
  };

  useEffect(() => {
    const sessionRecordFCIBO = storeService.session.get.movementFciRecordFci();
    if (sessionRecordFCIBO) {
      setRecordFCIBO(sessionRecordFCIBO);

      if (helpers.option.fci.doRecord(sessionRecordFCIBO)) {
        const sessionAnimalsSatisfyBO = storeService.session.get.movementFciAnimalsSatisfy();

        if (sessionAnimalsSatisfyBO) {
          setDoAnimalsSatisfyBO(sessionAnimalsSatisfyBO);

          if (helpers.option.animals.doNotSatisfy(sessionAnimalsSatisfyBO)) {
            const sessionUnfitAnimalsBO = storeService.session.get.movementFciUnfitAnimals();
            const sessionNonComplianceReasonBO = storeService.session.get.movementFciNonComplianceReason();
            const sessionWithdrawalPeriodBO = storeService.session.get.movementFciWithdrawalPeriod();
            const sessionHoldingRestrictionsBO = storeService.session.get.movementFciHoldingRestrictions();

            if (sessionUnfitAnimalsBO) {
              setUnfitAnimalTagNumbers(sessionUnfitAnimalsBO);
            }
            if (sessionNonComplianceReasonBO) {
              setNonComplianceReason(sessionNonComplianceReasonBO);
            }
            if (sessionWithdrawalPeriodBO) {
              setWithdrawalPeriod(sessionWithdrawalPeriodBO);
            }
            if (sessionHoldingRestrictionsBO) {
              setHoldingRestrictions(sessionHoldingRestrictionsBO);
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWithdrawalPeriod(fciData.withdrawalPeriod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fciData.withdrawalPeriod]);

  return (
    <>
      {ready &&
        <details className="accordion">
          <FoodChainInformationHelper
            animals={animals}
            batchList={batchList}
            choiceDisabled={choiceDisabled}
            disabledIcon={disabledIcon}
            doAnimalsSatisfy={doAnimalsSatisfyBO}
            fciData={fciData}
            metFCIWithdrawalPeriods ={metFCIWithdrawalPeriods}
            paginationParams={paginationParamsBO}
            panelTitle={panelTitle}
            recordFCI={recordFCIBO}
            setAnimals={setAnimalDetails}
            setDataChanged={setDataChanged}
            setDoAnimalsSatisfy ={setDoAnimalsSatisfyBO}
            setFciData={setFciData}
            setHoldingRestrictions={ setHoldingRestrictions}
            setModal={setModal}
            setNonComplianceReason ={setNonComplianceReason}
            setRecordFCI={setRecordFCIBO}
            setUnfitAnimalTagNumbers={setUnfitAnimalTagNumbers}
            setWithdrawalPeriod={setWithdrawalPeriod}
            speciesId={speciesId}
            unfitAnimalTagNumbers={unfitAnimalTagNumbers}
            withdrawalPeriod={withdrawalPeriod}
          />
          <div>
            {recordFCIBO === constants.option.recordFCI.yes &&
              <>

                {doAnimalsSatisfyBO === constants.option.animalsSatisfy.doNotSatisfy &&
                  <>
                    <div className={classes.foodChainInformation}>
                      <div className={classes.input}>
                        <FieldEntry
                          id="fciNonCompliance"
                          labelBold={true}
                          labelText="movements.fci-information.input-field-one"
                          name="fciNonCompliance"
                          onBlur={handleChanges.nonComplianceReason}
                          onChange={(event) => setNonComplianceReason(event.target.value)}
                          rows={config.ROWS_FCI_NON_COMPLIANCE}
                          tooltip="movements.fci-information.input-field-one-hint"
                          value={nonComplianceReason ? he.decode(nonComplianceReason) : ''}
                        />
                      </div>

                      <div className={classes.input}>
                        <FieldEntry
                          id="holdingRestrictions"
                          labelText={helpers.species.isDeerId(speciesId) ? 'movements.fci-information.input-field-three-deer' : 'movements.fci-information.input-field-three-sheepAndGoats'}
                          name="holdingRestrictions"
                          onBlur={handleChanges.changeHoldingRestrictions}
                          onChange={(event) => setHoldingRestrictions(event.target.value)}
                          rows={config.ROWS_HOLDING_RESTRICTIONS}
                          value={holdingRestrictions ? he.decode(holdingRestrictions) : ''}
                        />
                      </div>
                    </div>
                  </>
                }
              </>
            }
          </div>
        </details>
      }
    </>
  );
};

FoodChainInformation.propTypes = {
  animals: PropTypes.array,
  batchList: PropTypes.array,
  choiceDisabled: PropTypes.bool,
  disabledIcon: PropTypes.bool,
  fciData: PropTypes.object,
  holdingRestrictions: PropTypes.string,
  nonComplianceReason: PropTypes.string,
  panelTitle: PropTypes.string,
  setAnimalDetails: PropTypes.func,
  setDataChanged: PropTypes.func,
  setFciData: PropTypes.func.isRequired,
  setHoldingRestrictions: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  setNonComplianceReason: PropTypes.func,
  speciesId: PropTypes.number
};

export default FoodChainInformation;

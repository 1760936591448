// import appInsightsService from 'services/appInsightsService';
import axios from 'axios';
import Routing from 'routing';
import storeService from 'services/storeService';
import Hotjar from '@hotjar/browser';

const bffErrorList = [
  {
    errorCode: 500,
    errorMessages: [
      'status code 500',
      'Error: Network Error'
    ],
    id: 'error500',
    labelCancel: 'button.close',
    labelConfirm: null,
    modalMessage: 'uiError.500'
  },
  {
    errorCode: 404,
    errorMessages: [
      'status code 404'
    ],
    id: 'error404',
    labelCancel: 'button.close',
    labelConfirm: null,
    modalMessage: 'uiError.404'
  },
  {
    errorCode: 403,
    errorMessages: [
      'status code 403',
      'Error code: 403 - Forbidden'
    ],
    id: 'error403',
    labelCancel: 'button.close',
    labelConfirm: null,
    modalMessage: 'uiError.403'
  },
  {
    errorCode: 401,
    errorMessages: [
      'status code 401'
    ],
    id: 'error401',
    labelCancel: 'button.close',
    labelConfirm: 'button.login',
    modalMessage: 'uiError.401',
    redirect: Routing.logout
  },
  {
    errorCode: 400,
    errorMessages: [],
    id: 'error400',
    labelCancel: 'button.close'
  }
];

const errors = {
  findServerError: (message, status) => bffErrorList.find((bffErrorItem) => bffErrorItem.errorMessages.includes(message) || bffErrorItem.errorCode === status),

  BFF: (error, setModal) => {
    //appInsightsService.trackException(error);
    const data = error?.response?.data;
    const errorMessage = data?.errorMessage ?? data?.message ?? error;
    const errorStatus = error?.response?.status;

    const cookiesEnabled = (storeService.cookie.get.cookieConsent() === '2');
    if (cookiesEnabled && Hotjar.isReady()) { // only if the user has consented
      Hotjar.event('User Was Shown Error Popup');
    }

    if (!axios.isCancel(error)) {
      if (setModal && typeof setModal === 'function') {
        // const ai = appInsightsService.appInsights;
        // const operationId = ai.context?.telemetryTrace?.traceID;
        const message = errorMessage.toString();
        const matchedError = errors.findServerError(message, errorStatus);

        // if (operationId) {
        //   message = `${message}<br /><br />If contacting support, please quote the unique Operation Id:<br /><strong>${operationId}</strong>.`;
        // }

        if (!matchedError) {
          setModal({
            // modalTitle: error.message ? error.message : error,
            // modalMessage: message,
            modalTitle: 'error.invalidResponse-title',
            modalMessage: [
              'error.unexpectedError1',
              'error.unexpectedError2'
            ]
          });
        } else {
          const modalProps = {
            id: matchedError.id,
            labelCancel: matchedError.labelCancel,
            labelConfirm: matchedError.labelConfirm,
            modalConfirm: matchedError.modalConfirm,
            modalClose: matchedError.modalClose,
            modalMessage: matchedError.modalMessage || errorMessage,
            modalTitle: 'uiError.' + matchedError.errorCode + 'title'
          };

          if (matchedError.redirect) {
            modalProps.modalConfirm = () => {
              window.location.href = matchedError.redirect;
              return null;
            };
          }

          setModal(modalProps);
        }
      } else {
        alert(errorMessage);
      }
    }
  }
};

export default errors;

import React from 'react';
import classes from 'components/table/filters/filtersBoUserActivity/filtersBoUserActivity.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import storeService from 'services/storeService';
import { initialSorting } from 'services/tableColumns';
import DateRange from 'components/base/dateRange/dateRange';
import DropdownSearch from 'components/base/dropdownSearch/dropdownSearch';
import InputDate from 'components/base/inputDate/inputDate';

const filtersBoUserHoldingActivity = ({
  comingFromHolding = false,
  dateError,
  eventType,
  eventTypeOptions,
  filterDate,
  filterEventType,
  filterFromDate,
  filterToDate,
  fromDateError,
  getDates,
  history,
  loadPending,
  setData,
  setDateError,
  setFilterDate,
  setFilterEventType,
  setFilterFromDate,
  setFilterSpeciesName,
  setFilterToDate,
  setFromDateError,
  setRenderTable,
  setTableParams,
  setToDateError,
  storeFilters,
  toDateError
}) => {
  const isFromTo = (which, value, storedFilters) => {
    helpers.flags.isFrom(which) ? setFilterFromDate(value) : setFilterToDate(value);

    if ((value !== filterFromDate) || (value !== filterToDate)) {
      setRenderTable(false);
    }

    if (value?.length === 0) {
      setTableParams((prevState) => {
        // eslint-disable-next-line no-unused-vars
        const { fromDate, toDate, ...prevStateFilters } = prevState.filters;

        return {
          ...prevState,
          filters: prevStateFilters
        };
      });

      // eslint-disable-next-line no-unused-vars
      const { fromDate, toDate, ...prevStoredFilters } = storedFilters;

      storeService.session.set[`tableFilters${storeFilters}`]({
        ...prevStoredFilters
      });
    } else {
      if (helpers.flags.isFrom(which)) {
        setTableParams((prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            fromDate: value
          }
        }));

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          fromDate: value
        });
      }
      if (helpers.flags.isTo(which)) {
        setTableParams((prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            toDate: value
          }
        }));

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          toDate: value
        });
      }
    }
  };
  const isSingle = (value, storedFilters) => {

    setFilterDate(value);

    if (value !== filterDate) {
      setRenderTable(false);
    }

    if (value?.length === 0) {
      setTableParams((prevState) => {
        // eslint-disable-next-line no-unused-vars
        const { date, ...prevStateFilters } = prevState.filters;

        return {
          ...prevState,
          filters: prevStateFilters
        };
      });

      // eslint-disable-next-line no-unused-vars
      const { date, ...prevStoredFilters } = storedFilters;

      storeService.session.set[`tableFilters${storeFilters}`]({
        ...prevStoredFilters
      });
    } else {
      setTableParams((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          date: value
        }
      }));

      storeService.session.set[`tableFilters${storeFilters}`]({
        ...storedFilters,
        date: value
      });
    }
  };
  const handleChanges = {
    date: (which, value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      setData([]);
      storeService.session.removeAll.searchResults();

      if (helpers.flags.isFrom(which) || helpers.flags.isTo(which)) {
        isFromTo(which, value, storedFilters);
      } else if (helpers.flags.isSingle(which)) {
        isSingle(value, storedFilters);
      }
    },

    eventType: (value, doNotClearData) => {
      if (value && value !== filterEventType) {
        // eslint-disable-next-line no-unused-vars
        const { date, species, ...storedFilters } = storeService.session.get[`tableFilters${storeFilters}`]();

        setFilterDate('');
        if (comingFromHolding) {
          setFilterSpeciesName(null);
        }
        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          eventType: value,
          sortBy: comingFromHolding ? initialSorting.bo.holdingActivity[value].accessor : initialSorting.bo.userActivity[value].accessor,
          sortDirection: comingFromHolding ? initialSorting.bo.holdingActivity[value].direction : initialSorting.bo.userActivity[value].direction,
          useComponent: comingFromHolding ? constants.bo.holdingActivity.eventTypes[value].useComponent : constants.bo.userActivity.eventTypes[value].useComponent
        });

        if (!doNotClearData) {
          setData([]);
          storeService.session.removeAll.searchResults();
        }

        setFilterEventType(value);

        setRenderTable(false);

        setTableParams((prevState) => ({
          ...prevState,
          request: {
            url: comingFromHolding ? constants.bo.holdingActivity.eventTypes[value].endpoint : constants.bo.userActivity.eventTypes[value].endpoint
          },
          filters: {
            ...prevState.filters,
            eventType: value,
            useComponent: comingFromHolding ? constants.bo.holdingActivity.eventTypes[value].useComponent : constants.bo.userActivity.eventTypes[value].useComponent
          },
          sort: {
            by: comingFromHolding ? initialSorting.bo.holdingActivity[value].accessor : initialSorting.bo.userActivity[value].accessor,
            direction: comingFromHolding ? initialSorting.bo.holdingActivity[value].direction : initialSorting.bo.userActivity[value].direction
          }
        }));

        comingFromHolding ? history.push(constants.bo.holdingActivity.eventTypes[value].url) : history.push(constants.bo.userActivity.eventTypes[value].url);
      }
    }
  };

  return (
    <div className={classes.eventTypeAndDate}>
      <div className={classes.eventType}>
        <DropdownSearch
          disabled={loadPending}
          id="eventTypeDropdown"
          label="label.eventType"
          name="eventType"
          onChange={(event) => handleChanges.eventType(event.target.value)}
          optionList={eventTypeOptions}
          placeholder="prompt.pleaseSelect"
          value={filterEventType}
        />
      </div>

      {filterEventType &&
        <>
          {eventType?.showDate &&
            <div className={classes.date}>
              <InputDate
                blockStyling={true}
                disabled={loadPending}
                dontValidate={true}
                error={dateError}
                id="date"
                labelPosition="above"
                labelText="label.date"
                maxDate={getDates.dateMax()}
                onChange={(event) => handleChanges.date(constants.flags.single, event.target.value)}
                setError={setDateError}
                value={filterDate}
              />
            </div>
          }

          {eventType?.showDateRange &&
            <div className={classes.dateRange}>
              <DateRange
                disabled={loadPending}
                fromDate={filterFromDate}
                fromDateError={fromDateError}
                inline={true}
                loadPending={loadPending}
                maxRange={{
                  value: 1,
                  unit: constants.period.years
                }}
                setFromDate={(date) => handleChanges.date(constants.flags.from, date)}
                setFromDateError={setFromDateError}
                setToDate={(date) => handleChanges.date(constants.flags.to, date)}
                setToDateError={setToDateError}
                toDate={filterToDate}
                toDateError={toDateError}
              />
            </div>
          }
        </>
      }
    </div>
  );
};

export default filtersBoUserHoldingActivity;

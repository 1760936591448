import React from 'react';
import { useTranslation } from 'react-i18next';
import constants from 'services/constants';
import { tableColumns } from 'services/tableColumns';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import Table from 'components/table/table';
import storeService from 'services/storeService';
import ErrorLabel from 'components/base/errorLabel/errorLabel';

const FoodChainInformationHelper = ({
  animals,
  batchList,
  choiceDisabled,
  disabledIcon,
  doAnimalsSatisfy,
  fciData,
  metFCIWithdrawalPeriods,
  paginationParams,
  panelTitle,
  recordFCI,
  setAnimals,
  setDataChanged,
  setDoAnimalsSatisfy,
  setFciData,
  setHoldingRestrictions,
  setModal,
  setNonComplianceReason,
  setRecordFCI,
  setUnfitAnimalTagNumbers,
  setWithdrawalPeriod,
  speciesId,
  unfitAnimalTagNumbers,
  withdrawalPeriod
}) => {
  const { ready, t } = useTranslation();
  const permission = storeService.session.get.permissions();
  const dataHasChanged = () => {
    storeService.session.set.dataChanged(true);
    setDataChanged(true);
  };
  const handleChanges = {
    animalSelected: (tagNumber) => {
      setAnimals((prevState) => prevState.map((animal) => ({
        ...animal,
        unfit: animal.tagNumber === tagNumber ? !animal.unfit : animal.unfit
      })));

      setUnfitAnimalTagNumbers((prevState) => {
        if (prevState.includes(tagNumber)) {
          return prevState.filter((animalTagNumber) => animalTagNumber !== tagNumber);
        }

        return [...prevState, tagNumber];
      });

      setFciData((prevState) => {
        let unfitAnimals;
        if (prevState.unfitAnimals) {
          if (prevState.unfitAnimals.includes(tagNumber)) {
            unfitAnimals = prevState.unfitAnimals.filter((animalTagNumber) => animalTagNumber !== tagNumber);
          } else {
            unfitAnimals = [...prevState.unfitAnimals, tagNumber];
          }
        } else {
          unfitAnimals = [tagNumber];
        }
        if (permission !== 'backoffice') {
          storeService.session.set.movementFciUnfitAnimals(unfitAnimals);
        }
        return { ...prevState, unfitAnimals };
      });
    },

    recordFCI: (record) => {
      setRecordFCI(record);
      if (permission !== 'backoffice') {
        storeService.session.set.movementFciRecordFci(record);
      }
      if (helpers.option.fci.doNotRecord(record)) {
        setDoAnimalsSatisfy(null);
        setWithdrawalPeriod(null);
        setUnfitAnimalTagNumbers([]);
      } else if (batchList?.length > 0 && (!animals || animals?.length === 0)) {
        setDoAnimalsSatisfy(constants.option.animalsSatisfy.doSatisfy);
      }

      setFciData((prevState) => ({
        ...prevState,
        recordFCI: record,
        animalsSatisfy: record === constants.option.recordFCI.yes ? constants.option.animalsSatisfy.doSatisfy : null
      }));

      dataHasChanged();
    },
    setDoAnimalsSatisfy: (satisfy) => {
      storeService.session.set.movementFciAnimalsSatisfy(satisfy);

      setDoAnimalsSatisfy(satisfy);

      if (helpers.option.animals.doSatisfy(satisfy)) {
        setNonComplianceReason(null);
        setHoldingRestrictions(null);
        setWithdrawalPeriod(null);
        setUnfitAnimalTagNumbers([]);
      }

      setFciData((prevState) => ({
        ...prevState,
        animalsSatisfy: satisfy
      }));

      dataHasChanged();
    },

    withdrawalPeriod: (eventTarget) => {
      metFCIWithdrawalPeriods.forEach((record) => {
        if (record.code === eventTarget.value) {
          if (permission !== 'backoffice') {
            storeService.session.set.movementFciWithdrawalPeriod(record);
          }
          setWithdrawalPeriod(record);

          setFciData((prevState) => ({
            ...prevState,
            withdrawalPeriod: record
          }));
        }
      });
      dataHasChanged();
    },

    setRadioChoice: () => {
      const choice = batchList?.length > 0 &&
                (
                  !animals ||
                    animals?.length === 0
                )
        ? [constants.option.animalsSatisfy.doNotSatisfy]
        : [];
      return choice;
    }
  };

  return (
    <>
      {ready &&
                <>
                  <summary>
                    <h2><i className={disabledIcon ? 'bi' : 'bi bi-info-circle'} /> {t(panelTitle)}</h2>
                  </summary>

                  <div>
                    <div className="section">
                      <RadioButtons
                        classNames={['bo']}
                        id="recordFci"
                        ids={[
                          constants.option.recordFCI.yes,
                          constants.option.recordFCI.no
                        ]}
                        name="recordFCI"
                        onChange={(event) => handleChanges.recordFCI(event.target.value)}
                        value={recordFCI}
                      />
                    </div>
                    {recordFCI === constants.option.recordFCI.yes &&
                            <>
                              <div className="section">
                                <RadioButtons
                                  classNames={['bo']}
                                  disabled={choiceDisabled
                                    ? [
                                      constants.option.animalsSatisfy.doSatisfy,
                                      constants.option.animalsSatisfy.doNotSatisfy
                                    ]
                                    : handleChanges.setRadioChoice()}
                                  id="animalsSatisfy"
                                  ids={[
                                    constants.option.animalsSatisfy.doSatisfy,
                                    constants.option.animalsSatisfy.doNotSatisfy
                                  ]}
                                  name="boAnimalsSatisfy"
                                  onChange={(event) => handleChanges.setDoAnimalsSatisfy(event.target.value)}
                                  value={doAnimalsSatisfy}
                                />
                              </div>
                              {doAnimalsSatisfy === constants.option.animalsSatisfy.doNotSatisfy &&
                                    <>
                                      {fciData &&
                                            <Table
                                              columns={helpers.species.isDeerId(speciesId)
                                                ? tableColumns.fci.deer({ unfitAnimalTagNumbers, animalSelected: handleChanges.animalSelected })
                                                : tableColumns.fci.nonDeer({ unfitAnimalTagNumbers, animalSelected: handleChanges.animalSelected })
                                              }
                                              data={animals}
                                              dataProvided={true}
                                              paginationParams={paginationParams}
                                              setData={setAnimals}
                                              setModal={setModal}
                                            />
                                      }
                                      {metFCIWithdrawalPeriods &&
                                            <RadioButtons
                                              classNames={['bo']}
                                              id="withdrawalPeriod"
                                              ids={metFCIWithdrawalPeriods.map((item) => ({
                                                id: item.code,
                                                label: item.name
                                              }))}
                                              name="medicineWithdrawal"
                                              onChange={(event) => handleChanges.withdrawalPeriod(event.target)}
                                              value={withdrawalPeriod ? withdrawalPeriod.code : null}
                                            />
                                      }
                                      {!metFCIWithdrawalPeriods &&
                                            <ErrorLabel
                                              isWarning={true}
                                              label="label.noData"
                                            />
                                      }
                                    </>
                              }

                            </>
                    }
                  </div>

                </>
      }
    </>

  );
};
FoodChainInformationHelper.propTypes = {
  animals: PropTypes.array,
  batchList: PropTypes.array,
  choiceDisabled: PropTypes.bool,
  disabledIcon: PropTypes.bool,
  doAnimalsSatisfy: PropTypes.string,
  fciData: PropTypes.object,
  paginationParams: PropTypes.object,
  panelTitle: PropTypes.string,
  recordFCI: PropTypes.string,
  setAnimals: PropTypes.func,
  setDataChanged: PropTypes.func,
  setDoAnimalsSatisfy: PropTypes.func,
  setFciData: PropTypes.func,
  setHoldingRestrictions: PropTypes.func,
  setModal: PropTypes.func,
  setNonComplianceReason: PropTypes.func,
  setRecordFCI: PropTypes.func,
  setUnfitAnimalTagNumbers: PropTypes.func,
  setWithdrawalPeriod: PropTypes.func,
  speciesId: PropTypes.number,
  unfitAnimalTagNumbers: PropTypes.array,
  withdrawalPeriod: PropTypes.object
};

export default FoodChainInformationHelper;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/sections/paperAnimalIndividual/paperAnimalIndividual.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import { tableColumns } from 'services/tableColumns';
import AddTagRange from 'backOfficeComponents/addTagRange/addTagRange';
import FileUpload from 'backOfficeComponents/base/fileUpload/fileUpload';
import ManualTagEntry from 'backOfficeComponents/manualTagEntry/manualTagEntry';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import Table from 'components/table/table';

const PaperAnimalIndividual = ({
  animalDetails,
  pending,
  setAnimalDetails,
  setData,
  setDataChanged,
  setModal,
  setPending,
  setValidationState,
  speciesId
}) => {
  const { ready, t } = useTranslation();

  const [selectionMethod, setSelectionMethod] = useState(constants.option.selectionMethod.manualTagNumbers);
  const [paginationParams, setPaginationParams] = useState(constants.pagination.defaultParams);

  const handleChanges = {
    removeAllAnimals: () => {
      setAnimalDetails([]);
      setDataChanged();
      if (setValidationState && typeof setValidationState === 'function') {
        setValidationState((prevState) => prevState === 2 ? 0 : prevState);
      }
    },

    removeAllInvalid: () => {
      setAnimalDetails((prevState) => {
        const newAnimalDetails = helpers.animal.removeAllInvalid(prevState).map((animal) => ({
          tagNumber: animal.tagNumber,
          status: animal.status ? animal.status : '',
          uuid: animal.uuid ? animal.uuid : ''
        }));

        storeService.session.set.movementAnimalsBeingMoved(newAnimalDetails);

        return newAnimalDetails;
      });
      setDataChanged();
      if (setValidationState && typeof setValidationState === 'function') {
        setValidationState((prevState) => prevState === 2 ? 0 : prevState);
      }
    },

    removeAnimal: (animalToRemove) => {
      setAnimalDetails((prevState) => {
        const newAnimalDetails = prevState.filter((item) => item.uuid !== animalToRemove.uuid).map((animal) => ({
          tagNumber: animal.tagNumber,
          status: animal.status ? animal.status : '',
          uuid: animal.uuid ? animal.uuid : '',
          valid: animalToRemove.valid === 'Invalid' ? animal.valid : ''
        }));
        storeService.session.set.movementAnimalsBeingMoved(newAnimalDetails);
        return newAnimalDetails;
      });
      setDataChanged();
      if (setValidationState && typeof setValidationState === 'function') {
        setValidationState((prevState) => prevState === 2 ? 0 : prevState);
      }
    },

    selectionMethod: (method) => {
      setSelectionMethod(method);
      setDataChanged();
    }
  };

  const extraContent = animalDetails && animalDetails.length ? '(' + animalDetails.length + ')' : '';

  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      [constants.pagination.bottom]: {
        ...prevState[constants.pagination.bottom],
        total: animalDetails?.length
      }
    }));
  }, [animalDetails]);

  return (
    <>
      {ready &&
        <details className="accordion">
          <summary>
            <h2><i className="bi bi-tags" /> {t('movementDetails.animalsMovedTags.header')} {extraContent}</h2>
          </summary>
          <div>
            <RadioButtons
              classNames={['bo']}
              id="radioOptions"
              ids={[
                constants.option.selectionMethod.manualTagNumbers,
                constants.option.selectionMethod.tagNumberRange,
                constants.option.selectionMethod.uploadFile
              ]}
              inline={true}
              name="selectionMethod"
              onChange={(event) => handleChanges.selectionMethod(event.target.value)}
              reduceSpace={true}
              value={selectionMethod}
            />
            {helpers.option.selectionMethod.isUploadFile(selectionMethod) &&
              <FileUpload
                animalDetails={animalDetails}
                hideHint={true}
                id="paperAnimalUpload"
                pending={pending}
                setAnimalDetails={setAnimalDetails}
                setDataChanged={setDataChanged}
                setPending={setPending}
              />
            }
            {helpers.option.selectionMethod.isTagNumberRange(selectionMethod) &&
              <AddTagRange
                animalDetails={animalDetails}
                setAnimalDetails={setAnimalDetails}
                setDataChanged={setDataChanged}
                speciesId={speciesId}
              />
            }
            {helpers.option.selectionMethod.isManualTagNumbers(selectionMethod) &&
              <ManualTagEntry
                animalDetails={animalDetails ? animalDetails : []}
                id="manualTagEntry"
                setAnimalDetails={setAnimalDetails}
                setDataChanged={setDataChanged}
                title="movements.animal-selection.manual-tag-entry-heading"
              />
            }
            {animalDetails?.length > 0 &&
              <>
                <Table
                  columns={tableColumns.paperMovements.individual({ removeRow: handleChanges.removeAnimal })}
                  data={animalDetails}
                  dataProvided={true}
                  paginationParams={paginationParams}
                  removeAllAnimals={handleChanges.removeAllAnimals}
                  removeAllInvalid={handleChanges.removeAllInvalid}
                  setData={setData}
                  setModal={setModal}
                />

                <div className={classes.itemsTotal}>
                  <span className={classes.label}><i className="bi bi-tags" /> {t('movementDetails.animalsMoved.tagTotal')} {String(animalDetails.length)}</span>
                </div>
              </>
            }
          </div>
        </details>
      }
    </>
  );
};

PaperAnimalIndividual.propTypes = {
  animalDetails: PropTypes.array,
  pending: PropTypes.bool.isRequired,
  setAnimalDetails: PropTypes.func,
  setData: PropTypes.func.isRequired,
  setDataChanged: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setPending: PropTypes.func.isRequired,
  speciesId: PropTypes.number
};

export default PaperAnimalIndividual;

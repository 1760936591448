import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Prompt } from 'react-router';
import bff, { bffResponse } from 'services/bff';
import classes from 'backOffice/editMovement/editMovement.module.scss';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import { holdingHelper } from 'services/holdingHelper';
import navigation from 'services/navigation';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import Button from 'backOfficeComponents/base/button/button';
import Confirmation from 'components/base/confirmation/confirmation';
import DisplayValue from 'backOfficeComponents/base/displayValue/displayValue';
import Exemptions from 'backOfficeComponents/sections/exemptions/exemptions';
import FoodChainInformation from 'backOfficeComponents/sections/foodChainInformation/foodChainInformation';
import Legend from 'components/base/legend/legend';
import LocationAddressDate from 'backOfficeComponents/sections/locationAddressDate/locationAddressDate';
import ModalConfirm from 'components/base/modalConfirm/modalConfirm';
import PaperAnimalBatch from 'backOfficeComponents/sections/paperAnimalBatch/paperAnimalBatch';
import PaperAnimalIndividual from 'backOfficeComponents/sections/paperAnimalIndividual/paperAnimalIndividual';
import PaperTransport from 'backOfficeComponents/sections/paperTransport/paperTransport';
import PageTitle from 'backOfficeComponents/base/pageTitle/pageTitle';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import configHelper from 'services/configHelper';
import axios from 'axios';
import { cloneDeep } from 'lodash';

let departureDaysAhead = 1;

const mapHaulierName = (haulierName) => {
  if (haulierName && haulierName.includes(constants.option.haulierMapping.receivingKeeper)) {
    return constants.option.transporter.destinationKeeper;
  }
  if (haulierName && haulierName.includes(constants.option.haulierMapping.departureKeeper)) {
    return constants.option.transporter.departureKeeper;
  }
  if (haulierName && haulierName.includes(constants.option.haulierMapping.haulier)) {
    return constants.option.transporter.haulier;
  }
  return null;
};

const mapRecordedBy = (recordedBy) => {
  switch (recordedBy) {
    case constants.recordedBy.destination:
      return constants.option.transporter.destinationKeeper;

    case constants.recordedBy.departure:
      return constants.option.transporter.departureKeeper;

    default:
      return null;
  }
};

const setMargin = () => {
  const fixedActionsHeight = document.getElementById('fixedActions');
  document.getElementById('appContent').style.marginBottom = (fixedActionsHeight ? (fixedActionsHeight.offsetHeight) : 0) + 'px';
  // return fixedActionsHeight ? (fixedActionsHeight.offsetHeight) : 0;
};

const isNonCPH = (holding) => holding && !holdingHelper.cph.isNonCph(holding);

const get = {
  movementHolding: (movementData) => {
    if (isNonCPH(movementData.sourceHolding)) {
      return movementData.sourceHolding;
    }
    if (isNonCPH(movementData.fromHolding)) {
      return movementData.fromHolding;
    }
    if (isNonCPH(movementData.sourceHolding)) {
      return movementData.sourceHolding;
    }
    return isNonCPH(movementData.toHolding)
      ? movementData.toHolding
      : movementData.destinationHolding;
  },

  newDevices: (movementGroups, animalDetails) => {
    const newAnimalDetails = [];
    if (animalDetails) {
      animalDetails.forEach((animalDetail) => {
        if (movementGroups?.length > 0) {
          let found = false;
          for (let i = 0; (i < movementGroups.length) && !found; i++) {
            const nonMatchDevices = movementGroups[i].devices?.filter((device) => device.tagNumber === animalDetail.tagNumber);
            const nonMatchDevicesWithDetails = movementGroups[i].devicesWithDetail?.filter((item) => item.device.tagNumber === animalDetail.tagNumber);
            found = nonMatchDevices.length > 0 || nonMatchDevicesWithDetails.length > 0;
          }
          if (!found) {
            newAnimalDetails.push(animalDetail);
          }
        } else {
          newAnimalDetails.push(animalDetail);
        }
      });
    }
    return newAnimalDetails;
  },

  newBatches: (movementGroups, batchList) => {
    const newBatchList = [];
    if (batchList) {
      batchList.forEach((batchItem) => {
        if (movementGroups?.length > 0) {
          let found = false;
          for (let i = 0; (i < movementGroups.length) && !found; i++) {
            const matchedBatch = movementGroups[i].batches?.find((batch) => batch.batchNumber === batchItem.batchNumber && batch.animalTotal === batchItem.animalTotal);
            if (matchedBatch) {
              found = true;
            }
          }
          if (!found) {
            newBatchList.push(batchItem);
          }
        } else {
          newBatchList.push(batchItem);
        }
      });
    }
    return newBatchList;
  },

  newMovementGroups: (movementGroups, animalDetails, batchList) => {
    const newMovementGroups = cloneDeep(movementGroups);
    const result = cloneDeep(movementGroups);
    for (let group = 0; group < newMovementGroups?.length; group++) {
      const movementGroup = newMovementGroups[group];
      const devices = [];
      const batches = [];
      const devicesWithDetail = [];
      if (animalDetails) {
        animalDetails.forEach((animalDetail) => {
          const { tagNumber } = animalDetail;
          if (movementGroup?.devices?.length > 0) {
            const matchedTagNumber = movementGroup.devices.find((device) => device.tagNumber === tagNumber);
            if (matchedTagNumber) {
              devices.push(matchedTagNumber);
              movementGroup.devices = movementGroup.devices.filter((device) => device.tagNumber !== tagNumber);
            }
          }
          if (movementGroup?.devicesWithDetail?.length > 0) {
            const matchedTagNumber = movementGroup.devicesWithDetail.find((device) => device.device.tagNumber === tagNumber);
            if (matchedTagNumber) {
              devicesWithDetail.push(matchedTagNumber);
              movementGroup.devicesWithDetail = movementGroup.devicesWithDetail.filter((device) => device.device.tagNumber !== tagNumber);
            }
          }
        });
      }
      if (batchList) {
        batchList.forEach((batchItem) => {
          const { animalTotal, batchNumber } = batchItem;
          if (movementGroup?.batches?.length > 0) {
            const matchedBatchNumber = movementGroup.batches.find((batch) => batch.batchNumber === batchNumber && batch.animalTotal === animalTotal);
            if (matchedBatchNumber) {
              batches.push(matchedBatchNumber);
              movementGroup.batches = movementGroup.batches.filter((batch) => batch.batchNumber !== batchNumber && batch.animalTotal !== animalTotal);
            }
          }
        });
      }
      result[group].devices = devices;
      result[group].devicesWithDetail = devicesWithDetail;
      result[group].batches = batches;
    }
    return result;
  }
};

const BoEditMovement = ({
  setModal
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const sessionDataChanged = storeService.session.get.dataChanged();
  const fromHolding = storeService.session.get.movementFromHolding();
  const toHolding = storeService.session.get.movementToHolding();
  const isMarket = false;

  const ERR_TEMPLATE = { type: '', value: '' };

  const [movementReference, setMovementReference] = React.useState('');
  const [movementData, setMovementData] = React.useState(null);
  const [species, setSpecies] = React.useState({});

  const [departDataInit, setDepartDataInit] = React.useState({
    CPH: '',
    date: '',
    manualCPH: ''
  });
  const [departData, setDepartData] = React.useState(departDataInit);
  const [arriveDataInit, setArriveDataInit] = React.useState({
    CPH: '',
    date: '',
    manualCPH: '',
    numberReceived: ''
  });
  const [arriveData, setArriveData] = React.useState(arriveDataInit);

  const [transportData, setTransportData] = React.useState({});
  const [exemptionOptions, setExemptionOptions] = React.useState([]);
  const [batchListInit, setBatchListInit] = React.useState([]);
  const [batchList, setBatchList] = React.useState(batchListInit);
  const [batchTotal, setBatchTotal] = React.useState(0);
  const [animalDetailsInit, setAnimalDetailsInit] = React.useState([]);
  const [animalDetails, setAnimalDetails] = React.useState(animalDetailsInit);
  const [fciData, setFciData] = React.useState({});

  const [pageErrors, setPageErrors] = React.useState([]);
  const [pageWarnings, setPageWarnings] = React.useState([]);

  const [marketType, setMarketType] = React.useState('');
  const [movementType, setMovementType] = React.useState();
  const [departureDateError, setDepartureDateError] = React.useState(ERR_TEMPLATE);
  const [arrivalDateError, setArrivalDateError] = React.useState(ERR_TEMPLATE);
  const [departureHoldingError, setDepartureHoldingError] = React.useState(ERR_TEMPLATE);
  const [destinationHoldingError, setDestinationHoldingError] = React.useState(ERR_TEMPLATE);
  const [destinationDetailTotalAnimalsReceivedError, setDestinationDetailTotalAnimalsReceivedError] = React.useState(ERR_TEMPLATE);
  const [transportDataError, setTransportDataError] = React.useState(ERR_TEMPLATE);
  const [haulierNameError, setHaulierNameError] = React.useState(ERR_TEMPLATE);
  const [transportAuthNumberError, setTransportAuthNumberError] = React.useState(ERR_TEMPLATE);
  const [exitClicked, setExitClicked] = React.useState(false);

  const [nonComplianceReason, setNonComplianceReason] = React.useState();
  const [holdingRestrictions, setHoldingRestrictions] = React.useState();

  const [dataChanged, setDataChanged] = React.useState(sessionDataChanged ? sessionDataChanged : false);
  const [pending, setPending] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [movementRef, setMovementRef] = React.useState(null);
  const [paperId, setPaperId] = React.useState(null);
  const [pollingStatus, setPollingStatus] = React.useState(null);
  const [metFCIWithdrawalPeriods, setMetFCIWithdrawalPeriods] = React.useState([]);
  const [choiceDisabled, setChoiceDisabled] = React.useState(true);
  const [validationError, setValidationError] = React.useState(false);
  const [pmMove, setPmMove] = React.useState(false);
  const [isInBusinessMovement, setIsInBusinessMovement] = React.useState(false);
  const [cphValid, setCphValid] = React.useState(false);

  const clearErrors = () => {
    setPageErrors([]);

    setDepartureHoldingError(ERR_TEMPLATE);
    setDepartureDateError(ERR_TEMPLATE);

    setDestinationHoldingError(ERR_TEMPLATE);
    setArrivalDateError(ERR_TEMPLATE);

    setTransportDataError(ERR_TEMPLATE);
    setHaulierNameError(ERR_TEMPLATE);
    setTransportAuthNumberError(ERR_TEMPLATE);

    setDestinationDetailTotalAnimalsReceivedError(ERR_TEMPLATE);
  };

  const handleSubmissionResponse = (data) => {
    setPaperId(bffResponse.paperId(data));
    setMovementRef(bffResponse.movementRef(data));

    setValidationError(data.errorCode && data.errorCode === 400);
    setPageErrors(bffResponse.errors(data));
    setPageWarnings(bffResponse.warnings(data));
    setPollingStatus(bffResponse.status(data));
    if (data.poll) {
      setDataChanged(false);
      storeService.session.remove.dataChanged();
    }
  };

  const submit = () => {
    clearErrors();
    setSubmitted(true);
    setPending(true);

    const transportInformation = transportData && Object.keys(transportData)?.length > 0
      ? {
        recordTransportInformation: 'yes',
        transportedBy: mapHaulierName(transportData.transportedBy) ?? transportData.transportedBy,
        transporter: transportData.transporterName,
        vehicleRegistrationNumber: transportData.transportVehicleRegistrationNo,
        authorisationNumber: transportData.transportAuthorisationNumber
      }
      : {
        recordTransportInformation: 'no'
      };

    const mapSourceHolding = (inValue) => inValue.CPH ? inValue.CPH : (helpers.species.isCattleId(species.id) ? config.UNKNOWN : '');

    let userHolding;
    if (isInBusinessMovement) {
      userHolding = toHolding;
    } else if (movementData?.userHolding) {
      userHolding = movementData.userHolding;
    } else {
      userHolding = helpers.option.recordBy.isByDeparture(movementData.recordedBy) ? mapSourceHolding(departData) : arriveData.CPH;
    }
    const isByDestination = helpers.option.recordBy.isByDestination(movementData.recordedBy) ? constants.option.movement.on : constants.option.movement.off;
    const params = {
      poll: config.POLLS_ENABLED,
      amend: true,
      altErrors: true,
      movementType: (isInBusinessMovement ? constants.option.movement.on : isByDestination),
      trackingId: '',
      userHolding,
      animalCount: parseInt(arriveData.numberReceived),
      species: species.name,
      departureDate: helpers.date.formatForSubmission(departData.date),
      arrivalDate: helpers.date.formatForSubmission(arriveData.date),
      transportInformation: transportInformation,
      finalAnimalDetails: {
        animalsBeingMoved: animalDetails,
        batchList: batchList,
        numberOfAnimalsSelected: arriveData.numberReceived
      },
      exemptions: exemptionOptions ? exemptionOptions.filter((o) => o.value) : [],
      sourceHolding: mapSourceHolding(departData),
      destinationHolding: arriveData.CPH,
      fciData,
      originalMovementId: movementData.requestId
    };

    if (pmMove) {
      params.userHolding = departData.CPH;
      params.animalCount = parseInt(departData.numberReceived);
      params.comment = departData.comment;
      params.arrivalDate = helpers.date.formatForSubmission(departData.date);
      params.sourceHolding = departData.CPH;
      params.destinationHolding = departData.CPH;
    }

    const sessionRequest = storeService.session.get.request();
    if (sessionRequest) {
      params.finalAnimalDetails.animalsBeingMoved = get.newDevices(sessionRequest.movementGroups, animalDetails);
      params.finalAnimalDetails.batchList = get.newBatches(sessionRequest.movementGroups, batchList);
      sessionRequest.movementGroups = get.newMovementGroups(sessionRequest.movementGroups, animalDetails, batchList);
      params.previousRequest = sessionRequest;
    }

    bff
      .post('/movement', params)
      .then(({ data }) => {
        handleSubmissionResponse(data);
        setPending(false);
      })
      .catch((error) => {
        setSubmitted(false);
        setPending(false);
        errors.BFF(error, setModal);
      });
  };

  const dataHasChanged = () => {
    storeService.session.set.dataChanged(true);
    setDataChanged(true);
  };

  const pageContent = {
    animalBatch: () => (
      <PaperAnimalBatch
        batchList={batchList}
        setBatchList={setBatchList}
        setBatchTotal={setBatchTotal}
        setData={setBatchList}
        setDataChanged={dataHasChanged}
        setModal={setModal}
        speciesId={species.id}
      />
    ),

    individualAnimals: () => (
      <PaperAnimalIndividual
        animalDetails={animalDetails}
        pending={pending}
        setAnimalDetails={setAnimalDetails}
        setData={setAnimalDetails}
        setDataChanged={dataHasChanged}
        setModal={setModal}
        setPending={setPending}
        speciesId={species.id}
      />
    ),

    arrivalLocation: () => (
      <LocationAddressDate
        cphError={destinationHoldingError}
        data={arriveData}
        dateError={arrivalDateError}
        dateLabel="label.arrivalDate"
        id="receivingLocation"
        labelPosition={constants.labelPosition.above}
        labelText="label.cphNumber"
        numberReceivedError={destinationDetailTotalAnimalsReceivedError}
        numberReceivedLabel="label.numberReceived"
        panelTitle="label.receivingLocation"
        searchedData={arriveData}
        setCphValid={setCphValid}
        setData={setArriveData}
        setDataChanged={dataHasChanged}
        setDateError={setArrivalDateError}
        setModal={setModal}
        speciesId={species.id}
      />
    ),

    departureLocation: () => (
      <LocationAddressDate
        cphError={departureHoldingError}
        data={departData}
        dateError={departureDateError}
        dateLabel={pmMove ? 'movements.permitMove.date.title' : 'label.dateDeparture'}
        departureDaysAhead={departureDaysAhead}
        id="departureLocation"
        labelPosition={constants.labelPosition.above}
        labelText={pmMove ? 'movements.permitMove.cphNumber' : 'label.cphNumber'}
        maxDate={helpers.date.departureMax(movementType, departureDaysAhead, null)}
        movementType={movementType}
        numberReceivedError={pmMove ? destinationDetailTotalAnimalsReceivedError : null}
        numberReceivedLabel={pmMove ? 'movements.permitMove.numberMoved' : ''}
        panelTitle={pmMove ? 'boApp.sectionTitles.permitMoveLocation' : 'boApp.sectionTitles.departureLocation'}
        pmMove={pmMove}
        searchedData={departData}
        setCphValid={setCphValid}
        setData={setDepartData}
        setDataChanged={dataHasChanged}
        setDateError={setDepartureDateError}
        setModal={setModal}
        speciesId={species.id}
      />
    ),

    exemptions: () => (
      <>
        {species.id &&
          <Exemptions
            options={exemptionOptions}
            panelTitle="label.exemptions"
            permitMove={pmMove}
            setDataChanged={dataHasChanged}
            setModal={setModal}
            setOptions={setExemptionOptions}
            speciesId={species.id}
          />
        }
      </>
    ),

    fciDetails: () => (
      <FoodChainInformation
        animals={animalDetails}
        batchList={batchList}
        choiceDisabled={choiceDisabled}
        fciData={fciData}
        holdingRestrictions={holdingRestrictions}
        metFCIWithdrawalPeriods={metFCIWithdrawalPeriods}
        nonComplianceReason={nonComplianceReason}
        panelTitle="boApp.sectionTitles.fci"
        setAnimalDetails={setAnimalDetails}
        setDataChanged={dataHasChanged}
        setFciData={setFciData}
        setHoldingRestrictions={handleChanges.holdingRestriction}
        setModal={setModal}
        setNonComplianceReason={handleChanges.nonComplianceReason}
        speciesId={species.id}
      />
    ),

    marketType: () => (
      <div className="row">
        <div className="col-12">
          <Legend legend="radioButtons.marketType.label" />
          <div className="row govuk-!-padding-bottom-3">
            <div className="col-12">
              <RadioButtons
                ids={[
                  constants.option.market.green,
                  constants.option.market.red
                ]}
                inline={true}
                name="marketType"
                onChange={({ target }) => setMarketType(target.value)}
                reduceSpace={true}
                suppressLabel={true}
                value={marketType}
              />
            </div>
          </div>
        </div>
      </div>
    ),
    showIsByDestination: () => (
      helpers.option.recordBy.isByDestination(movementData?.recordedBy) ? 'radioButtons.movementType.on' : 'radioButtons.movementType.off'
    ),
    movementType: () => (
      <DisplayValue
        id="inline"
        labelPosition={constants.labelPosition.inline}
        labelText="movementDetails.searchResults.movementDirection"
        value={isInBusinessMovement ? 'radioButtons.movementType.on' : pageContent.showIsByDestination()}
      />
    ),

    transportDetails: () => (
      <PaperTransport
        haulierNameError={haulierNameError}
        id="paperTransport"
        panelTitle="boApp.sectionTitles.transportDetails"
        setDataChanged={dataHasChanged}
        setHaulierNameError={setHaulierNameError}
        setTransportAuthNumberError={setTransportAuthNumberError}
        setTransportDetails={setTransportData}
        setTransportDetailsError={setTransportDataError}
        transportAuthNumberError={transportAuthNumberError}
        transportDetails={transportData}
        transportDetailsError={transportDataError}
      />
    )
  };

  const handleChanges = {
    holdingRestriction: (restrictions) => {
      storeService.session.set.movementFciHoldingRestrictions(restrictions);
      setHoldingRestrictions(restrictions);

      dataHasChanged();
    },

    nonComplianceReason: (reason) => {
      storeService.session.set.movementFciNonComplianceReason(reason);
      setNonComplianceReason(reason);

      dataHasChanged();
    },

    withdrawalPeriod: (period) => {
      storeService.session.set.movementFciWithdrawalPeriod(period);

      dataHasChanged();
    }
  };

  useEffect(() => {
    setMargin();
  });

  useEffect(() => {
    if (dataHasChanged && cphValid && !pmMove) {
      const sources = [];
      const spawnToken = () => {
        sources.push(axios.CancelToken.source());
        return sources[sources.length - 1];
      };

      helpers.get.holdingsInBusiness(spawnToken.token, fromHolding)
        .then((holdingRes) => {
          if (helpers.response.isValid(holdingRes?.data?.data, setModal)) {
            const holdings = holdingRes.data?.data?.holdings || [];
            // in-business move is when both CPHs is in the same user holdings
            const isInBusiness = holdings.some((holding) => holding.cph === toHolding) &&
            holdings.some((holding) => holding.cph === fromHolding);
            setIsInBusinessMovement(isInBusiness);
          }
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            errors.BFF(error, setModal);
          }
        });

    }
  }, [cphValid]);

  useEffect(() => {
    setMovementData(storeService.session.get.movement());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.boEditMovements.length) !== Routing.boEditMovements) {
        storeService.session.remove.dataChanged();
        storeService.session.removeAll.confirm();
        storeService.session.removeAll.movement();
        unListen();
      }
    });

    window.onpopstate = () => {
      if (storeService.session.get.confirm()) {
        history.push(Routing.boSearchMovements);
      }
    };
  }, [history]);

  useEffect(() => {
    if (animalDetails?.length > 0 || batchList?.length > 0) {
      setChoiceDisabled(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animalDetails, batchList]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    helpers.get.metFciWithdrawalPeriods(source.token)
      .then(({ data }) => {
        if (helpers.response.isValid(data, setModal)) {
          setMetFCIWithdrawalPeriods(data);
        }
      });

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getRecordedByFromData = (data) => {
      if (data.transportHaulierName === constants.option.haulierMapping.haulier) {
        return constants.option.transporter.haulier;
      }
      if (data.movementDocument?.transportDetail) {
        const { transportDetail } = movementData.movementDocument;
        return transportDetail.transporterType
          ? (transportDetail.transporterType.name
            ? constants.option.transporterReverse[transportDetail.transporterType.name]
            : constants.option.transporterReverse[transportDetail.transporterType]
          )
          : (transportDetail.transportHaulierName
            ? mapHaulierName(transportDetail.transportHaulierName)
            : null
          );
      }
      if (data.recordedBy) {
        return mapRecordedBy(data.recordedBy);
      }
      if (data.transportInformation?.transportedBy) {
        return mapHaulierName(data.transportInformation.transportedBy);
      }
      return data.transportHaulierName ? mapHaulierName(data.transportHaulierName) : null;
    };

    if (movementData) {
      const fromHolding = movementData.sourceHolding ? movementData.sourceHolding : movementData.fromHolding;
      const toHolding = movementData.destinationHolding ? movementData.destinationHolding : movementData.toHolding;
      const movementRefNo = movementData.documentRef ? movementData.documentRef : (movementData.movementDocument ? movementData.movementDocument.movementDocumentRef : 'n/a');

      setMovementReference(movementRefNo);
      setMarketType();
      const recordByDestination = (helpers.option.recordBy.isByDestination(movementData.recordedBy) ? constants.option.movement.on : constants.option.movement.off);
      setMovementType(isInBusinessMovement ? constants.option.movement.on : recordByDestination);
      let speciesId;
      if (movementData.speciesId) {
        speciesId = movementData.speciesId;
        setSpecies({ id: speciesId, name: helpers.species.idToName(speciesId) });
      } else {
        speciesId = helpers.species.nameToId(movementData.species);
        setSpecies({ id: speciesId, name: movementData.species });
      }

      departureDaysAhead = configHelper.get.offMoveMaxDays(speciesId);

      const departureDetails = {
        CPH: movementData.sourceHolding ? movementData.sourceHolding : movementData.fromHolding,
        date: helpers.date.formatForSubmission(movementData.departureDate ? movementData.departureDate : movementData.movementDocument.departureDetail?.departureDateTime)
      };

      if (holdingHelper.cph.isNonCph(fromHolding) || holdingHelper.cph.isNonCph(toHolding)) {
        setPmMove(true);
        departureDetails.CPH = get.movementHolding(movementData);
        departureDetails.comment = movementData.comment;
        departureDetails.numberReceived = helpers.replaceNull(movementData.animalTotal);
      }
      setDepartData(departureDetails);
      setDepartDataInit(departureDetails);

      const arrivalDetails = {
        CPH: movementData.destinationHolding ?? movementData.toHolding,
        date: helpers.date.formatForSubmission(movementData.arrivalDate ? movementData.arrivalDate : movementData.movementDocument.destinationDetail?.arrivalDateTime),
        numberReceived: helpers.replaceNull(movementData.animalCount ?? movementData.animalTotal)
      };
      setArriveData(arrivalDetails);
      setArriveDataInit(arrivalDetails);

      const transportedBy = getRecordedByFromData(movementData);
      if (movementData.transportInformation) {
        setTransportData({
          transportedBy,
          transportVehicleRegistrationNo: movementData.transportInformation.vehicleRegistrationNumber,
          transporterName: movementData.transportInformation.transporter,
          transportAuthorisationNumber: movementData.transportInformation.authorisationNumber
        });
      } else if (movementData.movementDocument?.transportDetail) {
        const { transportDetail } = movementData.movementDocument;
        if (transportDetail) {
          const transportVehicleRegistrationNo = transportDetail.transportVehicleRegistrationNo
            ? transportDetail.transportVehicleRegistrationNo
            : null;

          const transportAuthorisationNumber = transportDetail.transporterAuthNumber
            ? transportDetail.transporterAuthNumber
            : null;

          const transporterName = transportDetail.transportHaulierName
            ? transportDetail.transportHaulierName
            : null;

          const transportedBy = helpers.lookup.transportedBy(transportDetail.transporterTypeId);

          if (transportedBy || transportVehicleRegistrationNo || transporterName || transportAuthorisationNumber) {
            setTransportData({ transportedBy, transportVehicleRegistrationNo, transporterName, transportAuthorisationNumber });
          }
        }
      } else {
        setTransportData({
          transportedBy,
          transportVehicleRegistrationNo: movementData.transportVehicleRegistrationNo,
          transporterName: movementData.transportHaulierName,
          transportAuthorisationNumber: null
        });
      }

      if (movementData.movementDocument?.fciDetail) {
        const isAllAnimalsFciCompliant = typeof movementData.movementDocument.fciDetail.isAllAnimalsFciCompliant === 'boolean' || typeof movementData.movementDocument.fciDetail.isAllAnimalsFCICompliant === 'boolean';
        const recordFCI = movementData.movementDocument.fciDetail && isAllAnimalsFciCompliant ? constants.option.recordFCI.yes : constants.option.recordFCI.no;

        setFciData({
          recordFCI,
          animalsSatisfy: recordFCI === constants.option.recordFCI.yes ? helpers.extract.animalsSatisfy(movementData.movementDocument.fciDetail) : null,
          holdingRestrictions: movementData.movementDocument.fciDetail.holdingRestrictions,
          nonComplianceReason: movementData.movementDocument.fciDetail.nonCompliantReason,
          withdrawalPeriod: helpers.extract.withdrawalPeriod(movementData.movementDocument.fciDetail, metFCIWithdrawalPeriods),
          unfitAnimals: movementData.movementDocument.fciDetail.nonCompliantDevices.map((item) => item.tagNumber)
        });
      }

      const animalDetailsToRestore = movementData.selectedAnimalDetails?.animalsBeingMoved ?? movementData.devices ?? [];
      const batchListToRestore = movementData.selectedAnimalDetails?.batchList ?? movementData.batches ?? [];

      setAnimalDetails(animalDetailsToRestore.map((animal) => ({
        ...animal,
        uuid: helpers.generateUUID(),
        unfit: movementData.movementDocument?.fciDetail?.nonCompliantDevices?.some((unfitAnimal) => unfitAnimal.tagNumber === animal.tagNumber)
      })));
      setAnimalDetailsInit(animalDetailsToRestore.map((animal) => ({
        ...animal,
        unfit: movementData.movementDocument?.fciDetail?.nonCompliantDevices?.some((unfitAnimal) => unfitAnimal.tagNumber === animal.tagNumber)
      })));
      setBatchList(batchListToRestore);
      setBatchListInit(batchListToRestore);

      setBatchTotal(batchListToRestore ? batchListToRestore.reduce((total, item) => total + parseInt(item.animalTotal), 0) : 0);

      const sessionMovementExemptions = storeService.session.get.movementExemptions();

      if (!sessionMovementExemptions) {
        let exemptions = [];
        if (movementData.processingFlags) {
          exemptions = movementData.processingFlags;
        } else if (movementData.approvedMovementProcessingFlags) {
          exemptions = movementData.approvedMovementProcessingFlags;
        }
        if (exemptions?.length > 0) {
          storeService.session.set.dataChanged(true);
          const movementExemptions = exemptions.map((exemption) => ({ id: exemption.processingFlagId, value: true }));
          storeService.session.set.movementExemptions(movementExemptions);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movementData, metFCIWithdrawalPeriods]);

  useEffect(() => {
    helpers.scrollToTop(document.getElementById('appContent'));
  }, []);

  const displayConfirmation = () => {
    const confirmationClick = () => {
      storeService.session.removeAll.movement();
      storeService.session.removeAll.confirm();
      history.push(navigation.movements());
    };
    const searchByMovementReference = () => helpers.redirect.searchByMovementReference(history, movementRef);
    const searchByPaperId = () => helpers.redirect.searchByPaperId(history, paperId);

    const withErrors = pageErrors?.length > 0;
    const withWarnings = pageWarnings?.length > 0;
    const isUndoMovementErrorCode = pageErrors?.some((item) => item.code === '4076');
    if (pageErrors?.some((item) => item.code === '4077')) {
      pageErrors.map((item) => {
        if (item.code === '4077') {
          item.message = t('label.unableAmmendMovementBO');
        }
        return item.message;
      });
    }
    const id = movementRef ? movementRef : (paperId ? paperId : null);
    const onClickEvent = movementRef ? searchByMovementReference : (paperId ? searchByPaperId : null);
    const buttons = (pageErrors?.length === 0 && pageWarnings?.length === 0) && (!validationError) ? [
      {
        id: 'recordAnother',
        label: 'button.recordAnotherMovement',
        onClick: confirmationClick
      }
    ] : null;

    return (
      <Confirmation
        buttons={buttons}
        confirm={{
          content: helpers.confirmation.getContent(withErrors, withWarnings, movementRef, validationError),
          footer: validationError ? {
            label: 'label.rectifyAndResubmit'
          } : {
            label: isUndoMovementErrorCode ? 'label.amendMovementNoContent' : 'label.amendMovementContent',
            onClick: onClickEvent
          },
          id,
          label: helpers.confirmation.getConfirmIdLabel(constants.postType.UPDATE, movementRef, paperId),
          onClick: validationError ? null : onClickEvent
        }}
        errors={pageErrors}
        label={helpers.confirmation.getTitle(withErrors, withWarnings, movementRef, validationError)}
        setModal={setModal}
        status={pollingStatus}
        warnings={pageWarnings}
      />
    );
  };

  return (
    <>
      {ready &&
        <>
          {(!submitted ||
            (
              submitted &&
              !pending &&
              validationError
            )
          ) &&
            <>
              <Prompt
                message={(params) => {
                  return (!storeService.session.get.idle() && dataChanged && (params.pathname.substring(0, Routing.boEditMovements.length) !== Routing.boEditMovements || params.pathname === Routing.boEditMovements)) ? t('boApp.label.confirmNavigateAwayAmendChanges') : true;
                }}
              />

              {exitClicked &&
                <ModalConfirm
                  id="exitAmendments"
                  modalClose={() => setExitClicked(false)}
                  modalConfirm={() => {
                    setDataChanged(false);
                    history.goBack();
                  }}
                  modalMessage="boApp.label.confirmExitAmend"
                  modalTitle="button.exit"
                />
              }

              {submitted && !pending && validationError && displayConfirmation()}

              <PageTitle
                id="editMovementTitle"
                pageTitleOne="boApp.pageTitles.movements.movements"
                pageTitleTwo={t('boApp.pageTitles.movements.editMovement') + ' ' + movementReference}
                speciesId={species.id}
              />
              <div className="pageContent">
                {isMarket && pageContent.marketType()}
                {pageContent.movementType()}
                {pageContent.departureLocation()}
                {pmMove ? null : pageContent.arrivalLocation()}
                {pageContent.transportDetails()}
                {pageContent.exemptions()}
                {pageContent.fciDetails()}
                {pageContent.animalBatch()}
                {pageContent.individualAnimals()}

                <div className={classes.itemsTotal}>
                  <i className={'bi bi-list-check ' + classes.icon} />
                  <span className={classes.label}>{t('label.animalTotal')} {String(batchTotal + (animalDetails ? animalDetails.length : 0))}</span>
                </div>
              </div>
              <div className={classes.fixedActions} id="fixedActions">
                <div className="actions fixedActions">
                  <div className="left">
                    <Button
                      buttonType="primary"
                      disabled={!dataChanged}
                      id="movementSubmitButton"
                      label="button.submit"
                      onClick={submit}
                    />
                  </div>
                  <div className="right">
                    <Button
                      buttonType="tertiary"
                      id="cancelButton"
                      label="button.exitNoChanges"
                      onClick={() => setExitClicked(true)}
                    />
                  </div>
                </div>
              </div>
            </>
          }

          {submitted && pending &&
            <Confirmation
              label="label.submissionPending"
              setModal={setModal}
              status={constants.status.pending}
            />
          }

          {submitted && !pending && !validationError && pageErrors?.length >= 0 && pageWarnings?.length >= 0 && displayConfirmation()}
        </>
      }
    </>
  );
};

BoEditMovement.propTypes = {
  setModal: PropTypes.func.isRequired
};

export default BoEditMovement;

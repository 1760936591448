import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Redirect } from 'react-router-dom';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';
import Routing from 'routing';
import { bffStatus } from 'services/bff';
import navigation from 'services/navigation';
import userRoleType from 'services/userRoleType';
import permissions from 'services/permissions';
import storeService from 'services/storeService';
import Confirmation from 'components/base/confirmation/confirmation';
import DeathsStep1 from 'frontEnd/deaths/step1/deathsStep1';
import DeathsStep2 from 'frontEnd/deaths/step2/deathsStep2';
import Summary from 'frontEnd/deaths/summary/summary';
import TabsNavigational from 'components/base/tabsNavigational/tabsNavigational';
import constants from 'services/constants';

const promptPathList = [
  Routing.home,
  Routing.keeperHolding,
  Routing.movements,
  Routing.movementsStep1,
  Routing.missingFound,
  Routing.animals,
  Routing.taggingApplications,
  Routing.holdingRegisterDetails,
  Routing.logout
];

const STEP_01 = '1';
const STEP_02 = '2';

const Deaths = ({
  location,
  setModal,
  setPermission
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const holding = storeService.session.get.holding();

  const sessionDeathData = storeService.session.get.death();
  const sessionDataChanged = storeService.session.get.dataChanged();
  const role = storeService.session.get.permissions();

  const [deathData, setDeathData] = React.useState(sessionDeathData ?? null);

  const [dataChanged, setDataChanged] = React.useState(sessionDataChanged ?? false);

  const confirmation = {
    viewSummary: (requestId, requestType) => ({
      id: 'viewSummary',
      label: 'button.viewSummary',
      onClick: () => {
        setPermission(userRoleType.GENERIC);
        storeService.session.set.permissions(userRoleType.GENERIC);
        storeService.session.remove.holding();

        setTimeout(() => {
          history.push({
            pathname: Routing.activityDetails + requestId,
            state: {
              data: {
                requestId,
                requestType,
                current: {
                  returnUrl: permissions.isKeeper() ? Routing.deaths : Routing.userActivity,
                  permissions: role,
                  holding
                }
              }
            }
          });
        }, [100]);
      }
    }),

    returnToUserActivity: () => ({
      id: 'returnToUserActivity',
      label: 'button.returnToUserActivity',
      onClick: () => {
        storeService.session.remove.dataChanged();
        storeService.session.set.permissions(userRoleType.GENERIC);
        setPermission(userRoleType.GENERIC);
        history.push(Routing.userActivity);
      }
    })
  };

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.deaths.length) !== Routing.deaths) {
        storeService.session.remove.dataChanged();
        storeService.session.removeAll.confirm();
        storeService.session.removeAll.death();
        storeService.session.removeAll.searchResults();
        storeService.session.removeAll.tableFilters();
        unListen();
      }
    });

    window.onpopstate = () => {
      if (storeService.session.get.confirm()) {
        history.push(Routing.deaths);
      }
    };
  }, [history]);

  const stepPicker = (pathname) => {
    const routingsNeedDeathData = [Routing.deathsStep2, Routing.deathsSummary];

    if (routingsNeedDeathData.includes(pathname) && !deathData) {
      return <Redirect to={Routing.deathsStep1} />;
    }

    switch (pathname) {
      case Routing.deathsStep1:
      default: {
        storeService.session.removeAll.confirm();

        return (
          <TabsNavigational>
            <div active id={constants.tabs.deaths} label="label.deaths">
              <span className="caption">
                {t('deaths.step-heading')}
                {STEP_01}
                {t('deaths.step-heading-pages')}
              </span>
              <DeathsStep1
                deathData={deathData}
                setDataChanged={setDataChanged}
                setDeathData={setDeathData}
                setModal={setModal}
              />
            </div>
            <div id={constants.tabs.missingAndFound} label="label.missing&Found" url={Routing.missingFound} />
          </TabsNavigational>
        );
      }

      case Routing.deathsStep2: {
        return (
          <>
            <span className="caption">
              {t('deaths.step-heading')}
              {STEP_02}
              {t('deaths.step-heading-pages')}
            </span>
            <DeathsStep2
              deathData={deathData}
              setDeathData={setDeathData}
              setModal={setModal}
            />
          </>
        );
      }

      case Routing.deathsSummary: {
        storeService.session.remove.deathAmend();
        return (
          <Summary
            currentHolding={holding}
            deathData={deathData}
            setData={setDeathData}
            setDataChanged={setDataChanged}
            setModal={setModal}
          />
        );
      }

      case Routing.deathsConfirm: {
        const requestId = storeService.session.get.confirmRequestId();
        const pollingStatus = storeService.session.get.confirmPollingStatus();
        const pollingErrors = storeService.session.get.confirmPollingErrors();
        const pollingWarnings = storeService.session.get.confirmPollingWarnings();

        if (!requestId) {
          return <Redirect to={Routing.deathsStep1} />;
        }

        return (
          <Confirmation
            buttons={[
              requestId && !bffStatus.isClaPending(pollingStatus) ? confirmation.viewSummary(requestId, constants.option.requestType.death) : null
            ].filter((item) => item)}
            confirm={{
              id: requestId,
              label: 'label.reference',
              onClick: confirmation.returnToUserActivity().onClick
            }}
            errors={pollingErrors}
            isUnamendable={true}
            label="label.deathReportSubmitted"
            setModal={setModal}
            status={pollingStatus}
            warnings={pollingWarnings}
          />
        );
      }
    }
  };

  React.useEffect(() => {
    const unListen = history.listen((path) => {
      const amend = storeService.session.get.deathAmend() ?? false;
      if (path.pathname === Routing.deathsStep1 && amend === false) {
        storeService.session.remove.dataChanged();
        storeService.session.removeAll.death();
        storeService.session.removeAll.confirm();
        unListen();
      }
    });

    window.onpopstate = () => {
      if (storeService.session.get.confirm()) {
        history.push(navigation.deaths());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      {ready &&
        <>
          <Prompt
            message={(params) => {
              const amend = storeService.session.get.deathAmend() ?? false;
              return (!storeService.session.get.idle() && dataChanged && ((amend !== true && params.pathname === Routing.deathsStep1) ||
              promptPathList.includes(params.pathname))
              ) ? t('warning.unsavedChanges2') : true;
            }}
          />
          <h1 className="h1">{t('label.deaths')}</h1>
          {/* {location.pathname === Routing.deathsStep2 && <p className="p">{t(recordDeathLabel)}</p>} */}
          {stepPicker(location.pathname)}
        </>
      }
    </>
  );
};

Deaths.propTypes = {
  location: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired
};

export default Deaths;

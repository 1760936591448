import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from 'components/table/filters/filtersBoHoldingActivity/filtersBoHoldingActivity.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import { tableColumns, initialSorting } from 'services/tableColumns';
import Button from 'backOfficeComponents/base/button/button';
import CheckboxGroup from 'components/base/checkboxGroup/checkboxGroup';
import CPHEntry from 'backOfficeComponents/cphEntry/cphEntry';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import FiltersBoUserHoldingActivity from 'components/table/filters/filtersBoUserHoldingActivity';
const BoHoldingActivityFilters = ({
  eventType,
  hasData,
  loadPending,
  setData,
  setModal,
  setRenderTable,
  setTableParams,
  storeFilters,
  tableParams
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const defaultEventTypeOptions = Object.entries(constants.bo.holdingActivity.eventTypes).map(([itemKey, itemValue]) => ({
    label: t(itemValue.label),
    name: t(itemValue.label),
    value: itemKey
  }));

  const [filterCph, setFilterCph] = React.useState(tableParams?.filters?.cph);
  const [filterEventType, setFilterEventType] = React.useState(tableParams?.filters?.eventType);
  const [filterDate, setFilterDate] = React.useState(tableParams?.filters?.date ? tableParams.filters.date : '');
  const [filterFromDate, setFilterFromDate] = React.useState(tableParams?.filters?.fromDate ? tableParams.filters.fromDate : '');
  const [filterToDate, setFilterToDate] = React.useState(tableParams?.filters?.toDate ? tableParams.filters.toDate : '');
  const [filterSpeciesName, setFilterSpeciesName] = React.useState(tableParams?.filters?.speciesName);
  const [filterMovementType, setFilterMovementType] = React.useState(tableParams?.filters?.movementType);
  const [cphValid, setCphValid] = React.useState(false);
  const [searchDisabled, setSearchDisabled] = React.useState(true);
  const [dateError, setDateError] = React.useState('');
  const [fromDateError, setFromDateError] = React.useState('');
  const [toDateError, setToDateError] = React.useState('');
  const [eventTypeOptions, setEventTypeOptions] = React.useState(defaultEventTypeOptions);
  const [cphType, setCphType] = React.useState();

  const getDates = {
    dateMax: () => {
      const today = new Date();

      return helpers.date.formatYYYYMMDD(today);
    }
  };

  const handleChanges = {
    cph: (value) => {
      // eslint-disable-next-line no-unused-vars
      const { date, eventType, fromDate, speciesId, speciesName, toDate, ...storedFilters } = storeService.session.get[`tableFilters${storeFilters}`]();

      setFilterCph(value);
      setFilterEventType(null);
      setFilterDate('');
      setFilterFromDate('');
      setFilterToDate('');
      setFilterSpeciesName(null);

      setData([]);
      storeService.session.removeAll.searchResults();

      if (value !== filterCph) {
        setRenderTable(false);
      }

      if (value?.length === 0) {
        setTableParams((prevState) => {
          // eslint-disable-next-line no-unused-vars
          const { cph, ...prevStateFilters } = prevState.filters;

          return {
            ...prevState,
            filters: prevStateFilters
          };
        });

        // eslint-disable-next-line no-unused-vars
        const { cph, ...prevStoredFilters } = storedFilters;

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...prevStoredFilters
        });
      } else {
        setTableParams((prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            cph: value
          }
        }));

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          cph: value
        });

        history.push(Routing.boHoldingActivity);
      }
    },
    holdingEventType: (valHolding, doNotClearDataHolding) => {
      if (valHolding && valHolding !== filterEventType) {
        // eslint-disable-next-line no-unused-vars
        const { date, species, ...storedFilters } = storeService.session.get[`tableFilters${storeFilters}`]();

        setFilterDate('');
        setFilterSpeciesName(null);

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          eventType: valHolding,
          sortBy: initialSorting.bo.holdingActivity[valHolding].accessor,
          sortDirection: initialSorting.bo.holdingActivity[valHolding].direction,
          useComponent: constants.bo.holdingActivity.eventTypes[valHolding].useComponent
        });

        if (!doNotClearDataHolding) {
          setData([]);
          storeService.session.removeAll.searchResults();
        }

        setFilterEventType(valHolding);

        setRenderTable(false);

        setTableParams((prevState) => ({
          ...prevState,
          request: {
            url: constants.bo.holdingActivity.eventTypes[valHolding].endpoint
          },
          filters: {
            ...prevState.filters,
            eventType: valHolding,
            useComponent: constants.bo.holdingActivity.eventTypes[valHolding].useComponent
          },
          sort: {
            by: initialSorting.bo.holdingActivity[valHolding].accessor,
            direction: initialSorting.bo.holdingActivity[valHolding].direction
          }
        }));

        history.push(constants.bo.holdingActivity.eventTypes[valHolding].url);
      }
    },
    movementType: (valueMovement) => {
      const storedFiltersMovement = storeService.session.get[`tableFilters${storeFilters}`]();

      if (valueMovement === filterMovementType) {
        setFilterMovementType('');

        delete storedFiltersMovement.movementType;

        storeService.session.set[`tableFilters${storeFilters}`](storedFiltersMovement);
      } else {
        setFilterMovementType(valueMovement);

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFiltersMovement,
          movementType: valueMovement
        });
      }

      setData([]);
      storeService.session.removeAll.searchResults();

      setRenderTable(false);

      setTableParams((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          movementType: valueMovement
        }
      }));
    },

    speciesName: (valueSpecies) => {
      const storedFiltersSpecies = storeService.session.get[`tableFilters${storeFilters}`]();

      if (valueSpecies === filterSpeciesName) {
        setFilterSpeciesName('');

        delete storedFiltersSpecies.speciesId;
        delete storedFiltersSpecies.speciesName;

        storeService.session.set[`tableFilters${storeFilters}`](storedFiltersSpecies);
      } else {
        setFilterSpeciesName(valueSpecies);

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFiltersSpecies,
          speciesId: helpers.species.nameToId(valueSpecies),
          speciesName: helpers.species.formatName(valueSpecies)
        });
      }

      setData([]);
      storeService.session.removeAll.searchResults();

      setRenderTable(false);

      setTableParams((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          speciesName: valueSpecies
        }
      }));
    }
  };

  const getColumnsByEventType = () => {
    if (filterEventType === 'annualInventory') {
      return tableColumns.bo.holdingActivity[filterEventType]({ speciesId: filterSpeciesName ? helpers.species.nameToId(filterSpeciesName) : 0 });
    }

    return tableColumns.bo.holdingActivity[filterEventType];
  };

  const runRequest = () => {
    storeService.session.set[`tableFilters${storeFilters}PageIndex`](0);
    storeService.session.removeAll.searchResults();
    setData([]);

    setTableParams((prevState) => ({
      ...prevState,
      columns: getColumnsByEventType(),
      filters: {
        cph: filterCph,
        eventType: filterEventType.toLowerCase(),
        onHoldingDate: filterDate,
        fromDate: filterFromDate,
        toDate: filterToDate,
        speciesId: filterSpeciesName ? helpers.species.nameToId(filterSpeciesName) : null,
        speciesName: helpers.species.formatName(filterSpeciesName),
        movementType: filterMovementType,
        useComponent: constants.bo.holdingActivity.eventTypes[filterEventType].useComponent
      },
      page: {
        ...prevState.page,
        index: 0
      },
      request: {
        ...prevState.request,
        params: {
          ...prevState.request.params,
          cph: filterCph,
          eventType: filterEventType.toLowerCase(),
          fromDate: filterFromDate,
          toDate: filterToDate,
          speciesId: filterSpeciesName ? helpers.species.nameToId(filterSpeciesName) : null,
          movementType: filterMovementType,
          queryKeeper: eventType.endpoint === '/holdingDetails' ? true : null,
          queryHoldingRegisters: eventType.endpoint === '/holdingDetails' ? true : null,
          queryHoldingIntegration: eventType.endpoint === '/holdingDetails' ? true : null,
          queryUserByCPH: eventType.endpoint === '/holdingDetails' ? true : null
        },
        processData: (data) => {
          switch (eventType.url) {
            case Routing.boHoldingActivityAnimalsOnHolding:
            case Routing.boHoldingActivityTaggingApplications:
              return helpers.animal.addDobAndAgeForDisplay(data);
            case Routing.boHoldingActivityAssociatedHoldings:
              return data.holdings;
            case Routing.boHoldingActivityHoldingDetails:
              return { ...data[0] };
            default:
              return data;
          }
        }
      },
      sort: {
        by: initialSorting.bo.holdingActivity[filterEventType].accessor,
        direction: initialSorting.bo.holdingActivity[filterEventType].direction
      }
    }));
    setRenderTable(true);
  };

  useEffect(() => {
    if (
      !eventType.url || loadPending || dateError !== '' || fromDateError !== '' || toDateError !== '' || hasData ||
      (eventType?.showDate && !filterDate) ||
      (eventType?.showDateRange && (!filterFromDate || !filterToDate)) ||
      (eventType?.showSpecies?.length > 0 && eventType?.speciesRequired && !filterSpeciesName)
    ) {
      setSearchDisabled(true);
    } else {
      setSearchDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadPending,
    hasData,
    eventType,
    filterDate,
    filterFromDate,
    filterToDate,
    filterSpeciesName,
    fromDateError,
    toDateError
  ]);

  useEffect(() => {
    if ((eventType.url === Routing.boHoldingActivityAssociatedHoldings || eventType.url === Routing.boHoldingActivityHoldingDetails) && hasData) {
      runRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventType]);

  useEffect(() => {
    if (cphType === constants.cph.abattoir) {
      setEventTypeOptions(defaultEventTypeOptions.filter((item) => item.value !== 'animalsOnHolding'));
    } else {
      setEventTypeOptions(defaultEventTypeOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cphType]);

  useEffect(() => {
    if (filterEventType) {
      setTableParams((prevState) => ({
        ...prevState,
        columns: getColumnsByEventType(),
        request: {
          ...prevState.request,
          url: constants.bo.holdingActivity.eventTypes[filterEventType].endpoint,
          params: {
            ...prevState.request.params,
            cph: filterCph,
            eventType: filterEventType.toLowerCase(),
            fromDate: filterFromDate,
            toDate: filterToDate,
            speciesId: filterSpeciesName ? helpers.species.nameToId(filterSpeciesName) : null,
            movementType: filterMovementType
          },
          processData: (data) => {
            switch (eventType.url) {
              case Routing.boHoldingActivityAnimalsOnHolding:
              case Routing.boHoldingActivityTaggingApplications:
                return helpers.animal.addDobAndAgeForDisplay(data);
              case Routing.boHoldingActivityAssociatedHoldings:
                return data.holdings;
              default:
                return data;
            }
          }
        }
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ready &&
        <div className={classes.filters}>
          <div>
            <div className={classes.cph}>
              <CPHEntry
                addressClassNames={['inline']}
                data={filterCph}
                disabled={loadPending}
                errorClassNames={['inline-bo']}
                format={{
                  address: constants.address.format.inline,
                  name: constants.address.format.inline
                }}
                hideTooltip={true}
                labelText="label.holdingNumber"
                name="cph"
                setCphType={setCphType}
                setCphValid={setCphValid}
                setData={handleChanges.cph}
                setModal={setModal}
              />
            </div>

            {cphValid &&
              <>
                <FiltersBoUserHoldingActivity
                  comingFromHolding={true}
                  dateError={dateError}
                  eventType={eventType}
                  eventTypeOptions={eventTypeOptions}
                  filterDate={filterDate}
                  filterEventType={filterEventType}
                  filterFromDate={filterFromDate}
                  filterToDate={filterToDate}
                  fromDateError={fromDateError}
                  getDates={getDates}
                  history={history}
                  loadPending = {loadPending}
                  setData={setData}
                  setDateError={setDateError}
                  setFilterDate={setFilterDate}
                  setFilterEventType={setFilterEventType}
                  setFilterFromDate={setFilterFromDate}
                  setFilterSpeciesName={setFilterSpeciesName}
                  setFilterToDate={setFilterToDate}
                  setFromDateError={setFromDateError}
                  setRenderTable={setRenderTable}
                  setTableParams={setTableParams}
                  setToDateError={setToDateError}
                  storeFilters={storeFilters}
                  toDateError={toDateError}
                ></FiltersBoUserHoldingActivity>
                {filterEventType && eventType?.showSpecies?.length > 0 &&
                  <div className={classes.speciesAndMovementType}>
                    {!eventType.speciesRequired &&
                      <CheckboxGroup
                        disabled={loadPending}
                        inline={true}
                        label="label.species"
                        objects={eventType.showSpecies.map((item) => ({
                          id: item,
                          label: item,
                          value: filterSpeciesName === item
                        }))}
                        onChange={(event) => handleChanges.speciesName(event.target.id)}
                        optional={!eventType.speciesRequired}
                      />
                    }
                    {eventType.speciesRequired &&
                      <RadioButtons
                        disabled={loadPending ? eventType.showSpecies.map((item) => item.toLowerCase()) : null}
                        id="species"
                        ids={eventType.showSpecies.map((item) => item.toLowerCase())}
                        inline={true}
                        name="speciesName"
                        onChange={(event) => handleChanges.speciesName(event.target.value)}
                        optional={!eventType.speciesRequired}
                        reduceSpace={true}
                        suppressLabel={false}
                        value={filterSpeciesName?.toLowerCase()}
                      />
                    }

                    {eventType.url === Routing.boHoldingActivityMovements &&
                      <CheckboxGroup
                        disabled={loadPending}
                        inline={true}
                        label="label.movementType"
                        objects={[
                          {
                            id: constants.option.movement.on,
                            label: constants.option.movement.on.charAt(0).toUpperCase() + constants.option.movement.on.slice(1),
                            value: filterMovementType === constants.option.movement.on
                          },
                          {
                            id: constants.option.movement.off,
                            label: constants.option.movement.off.charAt(0).toUpperCase() + constants.option.movement.off.slice(1),
                            value: filterMovementType === constants.option.movement.off
                          }
                        ]}
                        onChange={(event) => handleChanges.movementType(event.target.id)}
                        optional={true}
                      />
                    }
                  </div>
                }
              </>
            }
          </div>

          {cphValid && filterEventType &&
            <>
              <div className={classes.submitButton}>
                <Button
                  buttonType="primary"
                  disabled={searchDisabled}
                  id="searchButton"
                  label="button.search"
                  onClick={runRequest}
                />
              </div>

              {(eventType.url === Routing.boHoldingActivityAssociatedHoldings || eventType.url === Routing.boHoldingActivityHoldingDetails) && hasData &&
                <div className={classes.navigate}>
                  <Button
                    buttonType="primary"
                    id="filterNavigate"
                    label={eventType.url === Routing.boHoldingActivityAssociatedHoldings
                      ? 'label.selectedHoldingDetails'
                      : 'label.associatedHoldings'
                    }
                    onClick={() => {
                      handleChanges.holdingEventType(eventType.url === Routing.boHoldingActivityAssociatedHoldings
                        ? 'holdingDetails'
                        : 'associatedHoldings',
                      true
                      );
                    }}
                  />
                </div>
              }
            </>
          }
        </div>
      }
    </>
  );
};

BoHoldingActivityFilters.propTypes = {
  eventType: PropTypes.object.isRequired,
  hasData: PropTypes.bool.isRequired,
  loadPending: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setRenderTable: PropTypes.func.isRequired,
  setTableParams: PropTypes.func.isRequired,
  storeFilters: PropTypes.string.isRequired,
  tableParams: PropTypes.object.isRequired
};

export default BoHoldingActivityFilters;
